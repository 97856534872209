import React from 'react';
import { makeStyles } from '@material-ui/core';
import InfoDialog from '../../components/InfoDialog';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center'
  }
}));

const DrugStoreAlertTermDialog = ({ onClose = null }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleOnClose = () => {
    setOpen(false);
    onClose();
  }

  return (
    <InfoDialog
      open={open}
      onConfirm={() => handleOnClose()}
      onClose={() => handleOnClose()}
      content={
        <div className={classes.container}>
          <h2 style={{ color: '#52ad52' }}>บริการเภสัชกรรมทางไกล</h2>
          <p style={{ fontSize: "18px" }}>รายการ รูปภาพ และข้อบ่งใช้ยาจัดทำขึ้นเพื่อ ให้เป็นไปตามการให้บริการโทรเวชกรรมทางไกลกระทรวงสาธารณะสุข เท่านั้น</p>
          <h2 style={{ color: 'red' }}>ไม่ได้ใช้เพื่อการโฆษณา ผลิตภัณฑ์สุขภาพ หรือการโฆษณาขายยาฉายภาพแต่อย่างใด</h2>
          <h2>และ</h2>
          <h2 style={{ color: 'red' }}>ผู้ป่วยไม่สามารถสั่งยาได้ด้วยตัวเอง</h2>
          <p style={{ fontSize: "18px" }}>ต้องผ่านการอนุมัติจากเภสัชกร หรือ มีใบสั่งยาจากแพทย์เท่านั้น</p>
        </div>
      }
    />
  );
}

export default DrugStoreAlertTermDialog;
