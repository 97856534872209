import React from 'react';
import { displayCurrency } from '../../helpers/currency';
import { calculateByItem, subtotal } from '../../helpers/utility';
import { Button } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ConfirmDialog from '../ConfirmDialog';
import Cell from '../Cell';
import Table from '../Table';

export default ({ readOnly, order, onSelect = () => { }, onDeselect = () => { } }) => {
  const [modal, setModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);

  const handleSelect = id => () => {
    onSelect(id);
  };

  const handleDeselect = id => () => {
    if (order.items.find(m => m.product.id === id).quantity === 1) {
      setCurrentId(id);
      setModal(true);
    } else {
      onDeselect(id);
    }
  };

  const forceDeselect = () => {
    onDeselect(currentId);
    setCurrentId(null);
    setModal(false);
  };

  return (
    <Table>
      {
        !readOnly && (
          <ConfirmDialog
            open={modal}
            onConfirm={forceDeselect}
            onClose={() => setModal(false)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            content="คุณสามารถเพิ่มรายการนี้ใหม่อีกครั้งในหน้าเลือกรายการ"
            title="ลบรายการนี้ออก?"
          />
        )
      }
      <TableHead>
        <TableRow>
          <Cell>รายละเอียด</Cell>
          <Cell align="center" style={{ minWidth: 90 }}>จำนวน</Cell>
          <Cell align="right">ราคา</Cell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          order.items.map((menu, index) => (
            <TableRow key={index}>
              <Cell>
                <span>{menu.product.name}</span><br />
              </Cell>
              <Cell align="center">
                {
                  !readOnly && (
                    <IconButton size="small" onClick={handleDeselect(menu.product.id)}>
                      <RemoveIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
                {` ${menu.quantity} `}
                {
                  !readOnly && (
                    <IconButton size="small" onClick={handleSelect(menu.product.id)}>
                      <AddIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
              </Cell>
              <Cell align="right">{displayCurrency(calculateByItem(menu))}</Cell>
            </TableRow>
          ))
        }
        {
          !order.items.length && (
            <TableRow>
              คุณยังไม่มีรายการสินค้า
            </TableRow>
          )
        }
        <TableRow>
          <Cell colSpan={1} align="right">
            ยอดสั่งซื้อ
          </Cell>
          <Cell colSpan={2} align="right">
            {displayCurrency(subtotal(order.items))}
          </Cell>
        </TableRow>
        {
          readOnly && (
            <>
              <TableRow>
                <Cell colSpan={1} align="right">
                  ค่าจัดส่ง
                </Cell>
                <Cell colSpan={2} align="right">
                  {displayCurrency(order.delivery_price)}
                </Cell>
              </TableRow>
              {!!order.discount_price && <TableRow>
                <Cell colSpan={1} align="right">
                  ส่วนลด
                </Cell>
                <Cell colSpan={2} align="right">
                  -{displayCurrency(order.discount_price)}
                </Cell>
              </TableRow>}
              <TableRow>
                <Cell colSpan={1} align="right">
                  ยอดรวม
                </Cell>
                <Cell colSpan={2} align="right">
                  {displayCurrency(order.grand_total_price)}
                </Cell>
              </TableRow>
            </>
          )
        }
      </TableBody>
    </Table>
  )
};