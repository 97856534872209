import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { postAddress } from '../../actions/shippingInfo';
import { clearMenu } from '../../actions/menus';
import { createLoadingSelector } from '../../reducers/api';
import { locationFromGeo } from '../../apis/geo';
import { debounce } from '../../helpers/utility';
import * as Api from '../../apis';
import useCollaboration from '../../hooks/useCollaboration';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import LocationIcon from '@material-ui/icons/LocationOn';
import SaveIcon from '@material-ui/icons/Save';
import GoogleMap from '../../components/GoogleMap';
import Button from '../../components/Button';
import PlaceAutocompleteSlideDialog from '../../components/PlaceAutocompleteSlideDialog';
import InfoDialog from '../../components/InfoDialog';
import ConfirmDialog from '../../components/ConfirmDialog';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '100%',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0
  },
  icon: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '44%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  currentLocation: {
    position: 'absolute',
    bottom: 24,
    left: 8,
  },
  textField: {
    position: 'absolute',
    top: '12%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: 6,
    width: '90%',
  },
  save: {
    position: 'absolute',
    bottom: 24,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  locationIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  currentLocationButton: {
    height: '80px'
  },
  snackbarAnchor: {
    top: '140px'
  },
  snackbarContent: {
    justifyContent: 'center'
  }
}));

let mapInit = false;

const PickLocationPage = () => {
  const dispatch = useDispatch();
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const session = useSelector(state => state.session);
  const shippingInfo = useSelector(state => state.shippingInfo);
  const isLoading = useSelector(createLoadingSelector(['address/post']));
  const [openInfo, setOpenInfo] = useState(false);
  const [address, setAddress] = useState(shippingInfo.address ? shippingInfo.address.address : '');
  const [location, setLocation] = useState({
    latitude: shippingInfo.address ? parseFloat(shippingInfo.address.latitude) : 13.7633148,
    longitude: shippingInfo.address ? parseFloat(shippingInfo.address.longitude) : 100.5334619,
  });
  const [modal, setModal] = useState(false);
  const [collabId, setCollabId] = useState();
  const [collabChanged, setCollabChanged] = useState(false);
  const collaboration = useCollaboration(location);

  useEffect(() => {
    if (shippingInfo.address) {
      setAddress(shippingInfo.address.address);
      setLocation({
        latitude: parseFloat(shippingInfo.address.latitude),
        longitude: parseFloat(shippingInfo.address.longitude),
      });
    }
  }, [shippingInfo.address]);

  useEffect(() => {
    const id = collaboration?.tenant?.id;
    if (collabId && collabId !== id) {
      setCollabChanged(true);
    }
    if (id) {
      setCollabId(id);
    }
  }, [collaboration?.tenant?.id])

  useEffect(() => {
    setOpenInfo(true);
  }, []);

  const classes = useStyles();

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => setLocation(position.coords),
        (err) => alert('You need to enable location for this function to work. Please check your app permission.'),
        { timeout: 60000 }
      );
    } else {
      alert('Sorry, browser does not support geolocation!');
    }
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  }

  const openModal = () => {
    if (collabChanged) {
      setConfirmModal(true);
    } else {
      onSave();
    }
  }

  const onSave = () => {
    setConfirmModal(false);
    setCollabChanged(false);
    dispatch(postAddress(address, location.latitude, location.longitude))
      .then(() => {
        // work around get shipping distance because shippingInfo.distance not update in this function
        if (!process.env.REACT_APP_API_URL_PREFIX) { // Work around, show info dialog for drugsquare only
          Api.getShippingPrice(location.latitude, location.longitude).then(result => {
            if (result.distance > 20) {
              setShowInfoDialog(true);
            } else {
              window.history.back();
            }
          }).catch(() => {
            window.history.back();
          });
        } else {
          window.history.back();
        }
      });
  };

  const onLocationChange = (location) => {
    if (mapInit && !modal) {
      setLocation({ latitude: location.center.lat, longitude: location.center.lng });
      locationFromGeo(location.center.lat, location.center.lng)
        .then(results => {
          if (results[0]) {
            setAddress(results[0]['formatted_address']);
          }
        });
    }
    mapInit = true;
  };

  const onSuggestSelect = location => {
    if (!location) return
    setAddress(location.description);
    setLocation({
      latitude: location.location.lat,
      longitude: location.location.lng,
    });
    setModal(false);
  }

  const closeInfoDialog = () => {
    setShowInfoDialog(false);
    window.history.back();
  }

  return (
    <div className={classes.mapContainer}>
      <ConfirmDialog
        open={confirmModal}
        onConfirm={() => {
          dispatch(clearMenu());
          onSave()
        }}
        onClose={() => {
          setConfirmModal(false)
        }}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        content="หากท่านมีสินค้าอยู่ในตะกร้าจะต้องทำรายการใหม่"
        title="เปลี่ยนแปลงที่อยู่"
      />
      <InfoDialog
        open={showInfoDialog}
        okText={<span style={{ color: 'red' }}>ปิด</span>}
        onClose={closeInfoDialog}
        onConfirm={closeInfoDialog}
        disableBackdropClick={true}
        content={
          <div style={{ color: 'black' }}>
            เนื่องจากคุณ อยู่นอกระยะจัดส่งพื้นฐาน กรุณาติดต่อพนักงาน
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>กรุณาติดต่อ <a href="tel:0991402222">021026339</a></p>
          </div>
        }
      />
      <Snackbar
        classes={{
          anchorOriginTopCenter: classes.snackbarAnchor
        }}
        open={openInfo}
        autoHideDuration={5000}
        onClose={handleInfoClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <SnackbarContent
          classes={{
            root: classes.snackbarContent
          }}
          message={(
            <>
              <Box align="center">กรุณาเลือกที่อยู่</Box><br />
              <Box align="center">เราจะเลือก ร้านยา/เภสัช ที่ใกล้ที่สุดให้กับท่าน</Box>
            </>
          )}
        />
      </Snackbar>
      <PlaceAutocompleteSlideDialog
        open={modal}
        title="Search Place"
        onClose={() => setModal(false)}
        onSuggestSelect={onSuggestSelect}
      />
      {
        !modal && (
          <GoogleMap
            noMarker
            defaultZoom={14}
            center={location}
            onChange={debounce(onLocationChange, 500)}
          />
        )
      }
      <div className={classes.textField}>
        <Paper className={classes.paper}>
          <InputBase
            fullWidth
            disabled
            placeholder="ที่จัดส่ง"
            value={address}
            onClick={() => {
              setModal(true);
              mapInit = false;
            }}
          />
        </Paper>
      </div>
      <div className={classes.icon} >
        <LocationIcon fontSize="large" />
      </div>
      <div className={classes.currentLocation} >
        <Button
          variant="extended"
          type="fab"
          color="secondary"
          className={classes.currentLocationButton}
          onClick={getCurrentLocation}
          icon={
            <div className={classes.locationIcon}>
              <MyLocationIcon />
              <span>ตำแหน่ง</span>
              <span>ปัจจุบัน</span>
            </div>
          }
        />
      </div>
      <div className={classes.save} >
        <Button
          type="fab"
          variant="extended"
          color="secondary"
          onClick={openModal}
          loading={isLoading}
          icon={<SaveIcon />}
        >
          บันทึก
        </Button>
      </div>
    </div>
  );
};

export default PickLocationPage;
