import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { locationChange } from '../../actions/location';

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(locationChange(location));
  }, [location, dispatch]);
  return null;
}

