import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import createSentryMiddleware from 'redux-sentry-middleware';
// import * as Sentry from '@sentry/browser';

import logEventMiddleware from './middlewares/logEvent';
import errorMiddleware from './middlewares/error';

import reducers from '../reducers/index';

export default (initialState = {}) => {

  const middleware = [
    logEventMiddleware,
    errorMiddleware,
    thunk,
  ];

  let devToolsExtension = f => f
  if (process.env.HOST_ENV !== 'production') {
    middleware.push(require('redux-logger').default)
    if (typeof window !== 'undefined' && window.devToolsExtension) devToolsExtension = window.devToolsExtension()
  }
  // if (
  //   process.env.NODE_ENV === 'production' &&
  //   !!process.env.GATSBY_SENTRY &&
  //   typeof window !== 'undefined'
  // ) {
  //   Sentry.init({ dsn: process.env.GATSBY_SENTRY });
  //   middleware.push(createSentryMiddleware(Sentry))
  // }

  const enhancer = compose(applyMiddleware(...middleware), devToolsExtension)

  const store = createStore(
    reducers,
    initialState,
    enhancer
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
