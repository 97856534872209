import { useDispatch, useSelector } from 'react-redux';
import { setShippingInfo } from '../../actions/shippingInfo';
import { displayCurrency } from '../../helpers/currency';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MuiAlert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import Cell from '../Cell';
import Table from '../Table';
import SelectLocation from '../SelectLocation';
import useCollaboration from '../../hooks/useCollaboration';
import useOfficeHour from '../../hooks/useOfficeHour';

export default ({
  readOnly
}) => {
  const dispatch = useDispatch();
  const shippingInfo = useSelector(state => state.shippingInfo);
  const collaboration = useCollaboration(shippingInfo.address);
  const officeHour = useOfficeHour();

  let alertBox;
  if (!officeHour) {
    alertBox = <MuiAlert severity="info">เนื่องจากคุณอยู่นอกเวลาทำการ เราจะส่งสินค้าในวันถัดไปภายในเวลา 12:00 น.</MuiAlert>;
  }

  const handleSetShippingInfo = (payload) => {
    dispatch(setShippingInfo(payload));
  }

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <Cell style={{ minWidth: 90 }}>ชื่อ สกุล</Cell>
            <Cell>
              <Input
                fullWidth
                placeholder="eg. First Last"
                style={{ fontSize: '1em' }}
                disabled={readOnly}
                value={shippingInfo.name}
                onChange={(e) => handleSetShippingInfo({ name: e.target.value })}
              />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>เบอร์โทร.</Cell>
            <Cell>
              <Input
                fullWidth
                placeholder="090 999 9999"
                type="tel"
                style={{ fontSize: '1em' }}
                disabled={readOnly}
                value={shippingInfo.phone}
                onChange={(e) => handleSetShippingInfo({ phone: e.target.value })}
              />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>ที่จัดส่ง</Cell>
            <Cell>
              <SelectLocation address={shippingInfo.address} />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>หมายเหตุ</Cell>
            <Cell>
              <Input
                fullWidth
                multiline
                disabled={readOnly}
                value={shippingInfo.remark}
                placeholder="เช่น บ้านเลขที่ 11/11"
                onChange={(e) => handleSetShippingInfo({ remark: e.target.value })}
              />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>การจัดส่ง</Cell>
            <Cell>
              <Select
                native
                value={shippingInfo.deliveryMethod}
                onChange={(e) => handleSetShippingInfo({ deliveryMethod: e.target.value })}
                disabled={collaboration && !collaboration.within_the_range}
              >
                <option value="post_shipment" >ส่งปกติ 1-2 วัน</option>
                <option value="delivery">ส่งแบบด่วน ภายใน 4 ชม.</option>
              </Select>
            </Cell>
          </TableRow>
        {/* {
          !readOnly && (
            <TableRow>
              <Cell>
                ค่าจัดส่ง
              </Cell>
              <Cell>
                {displayCurrency(shippingInfo.deliveryPrice)}
              </Cell>
            </TableRow>
          )
        } */}
        </TableBody>
      </Table>
      {alertBox}
    </>
  );
};
