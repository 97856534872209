import { combineReducers } from 'redux';
import channels from './channels';
import messages from './messages';
import session from './session';
import api from './api';
import shippingInfo from './shippingInfo';
import menus from './menus';
import categories from './categories';
import pharmacists from './pharmacists';
import symptoms from './symptoms';
import creditCards from './creditCards';
import orders from './orders';
import users from './users';
import promotions from './promotions';

export default combineReducers({
  api,
  channels,
  session,
  messages,
  shippingInfo,
  menus,
  categories,
  pharmacists,
  symptoms,
  creditCards,
  orders,
  users,
  promotions,
})
