import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';
import { displayCurrency } from '../helpers/currency';

const byId = (state = {}, action) => {
  switch(action.type) {
    case Constants.MENUS_BY_CATEGORY_SUCCESS:
    case Constants.MENUS_SEARCH_SUCCESS:
    case Constants.MENU_GET_SUCCESS:
    case Constants.MENUS_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state);
    }
    // case Constants.SET_MENU_FROM_ORDER:
    //   return action.products.reduce((prev, product) => {
    //     return {
    //       ...prev,
    //       [product.id]: product
    //     }
    //   }, state);
    default: return state;
  }
}

const currentQuery = (state = {
  search: 'init',
  categoryId: null,
  results: []
}, action) => {
  switch(action.type) {
    case Constants.LOCATION_CHANGE:
      return {
        search: 'init',
        results: []
      };
    case Constants.MENUS_SEARCH_REQUEST:
      let results;
      if (
        state.search !== action.payload.search ||
        state.categoryId !== action.payload.categoryId
      ) {
        results = [];
      } else {
        results = state.results.slice();
      }
      return {
        ...state,
        results,
        categoryId: action.payload.categoryId,
        search: action.payload.search,
      };
    case Constants.MENUS_GET_SUCCESS:
    case Constants.MENUS_SEARCH_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        results: state.results.concat(action.payload.results.map(item => item.id)),
      };
    default: return state;
  }
}

const currentSelected = (state = [], action) => {
  switch(action.type) {
    case Constants.MENU_CLEAR:
      return [];
    // case Constants.LOCATION_CHANGE:
    //   if (action.payload.pathname === '/') return [];
    //   return state;
    // case Constants.SET_COLLABORATION:
    //     return [];
    // case Constants.ORDER_POST_SUCCESS:
    //   return [];
    case Constants.MENU_ADD: {
      return [...state, action.payload.id];
    }
    case Constants.MENU_SET_QUANTITY: {
      const newState = state.filter(id => action.payload.id !== id);
      return [...newState, ...Array(action.payload.quantity).fill(action.payload.id)];
    }
    case Constants.MENU_REMOVE: {
      const index = state.indexOf(action.payload.id);
      if (index > -1) {
        const s = state.slice(index);
        s.shift();
        return state.slice(0, index).concat(s);
      }
      return state;
    }
    default: return state;
  }
}

const searchMenuIds = (state = [], action) => {
  switch(action.type) {
    case Constants.LOCATION_CHANGE:
    // case Constants.SET_COLLABORATION:
      return [];
    case Constants.MENUS_BY_CATEGORY_SUCCESS:
    case Constants.MENUS_SEARCH_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        if (!prev.includes(item.id)) return [...prev, item.id];
        return prev;
      }, []);
    }
    default: return state;
  };
};

export default combineReducers({
  byId,
  currentSelected,
  searchMenuIds,
  currentQuery
});

export const createMenuByIdSelector = (state, id) => state.menus.byId[id]
export const createSearchMenusSelector = state => state.menus.searchMenuIds.map(id => state.menus.byId[id]);
export const createMenusSelector = state => state.menus.currentQuery.results.map(id => state.menus.byId[id]);
export const createInitialSelected = state => state.menus.currentSelected;
export const createCurrentQuery = state => state.menus.currentQuery;
export const createSelectedMenusSelector = state => {
  const counts = state.menus.currentSelected.reduce((prev, key) => {
    return {
      ...prev,
      [key]: {
        ...state.menus.byId[key],
        count: prev[key] ? prev[key].count + 1 : 1,
      }
    };
  }, {});
  return {
    items: Object.keys(counts).map(key => {
      return {
        product: counts[key],
        item_price: counts[key].price,
        quantity: counts[key].count,
      }
    })
  };
};
export const createSelectedMenusToMessageSelector = state => {
  const {items} = createSelectedMenusSelector(state);
  return items.map(item => {
    return {
      id: item.product.id,
      imageUrl: item.product.image.small,
      price: displayCurrency(item.item_price),
      quantity: item.quantity,
      title: item.product.name,
    }
  });
};
