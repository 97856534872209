import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ThreeDRotation from '@material-ui/icons/ThreeDRotation';
import img from '../../assets/images/empty-notification.png'

const useStyles = makeStyles((theme) => ({
  empty: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: 150
    }
  },
  compact: {
    position: 'relative',
    height: 'auto'
  }
}));

export default ({ children, compact }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.empty} ${compact && classes.compact}`}>
      <img src={img}></img>
      {children}
    </div>
  )
}
