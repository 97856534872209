import * as Constant from './constants'
import * as Api from '../apis'

export const addMenu = id => ({
  type: Constant.MENU_ADD,
  payload: {
    id
  }
});

export const clearMenu = () => ({
  type: Constant.MENU_CLEAR,
});

export const setMenuQuantity = (id, quantity) => ({
  type: Constant.MENU_SET_QUANTITY,
  payload: {
    id,
    quantity
  }
});

export const removeMenu = id => ({
  type: Constant.MENU_REMOVE,
  payload: {
    id
  }
});

export const getMenus = () => {
  return dispatch => {
    dispatch({ type: Constant.MENUS_GET_REQUEST });
    return Api.getMenus().then(payload => {
      dispatch({ type: Constant.MENUS_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.MENUS_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const searchMenus = (search, categoryId, page) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.MENUS_SEARCH_REQUEST, payload: { search, categoryId } });
    let filter = {};
    const { shippingInfo } = getState();
    let lat, lng;
    if (shippingInfo.address) {
      lat = shippingInfo.address.latitude;
      lng = shippingInfo.address.longitude;
    }
    let collaboration = shippingInfo.collaboration?.id;
    return Api.getMenus(search, categoryId, page, lat, lng, collaboration, filter).then(payload => {
      dispatch({ type: Constant.MENUS_SEARCH_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.MENUS_SEARCH_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getMenusByCategory = categoryId => {
  return (dispatch) => {
    dispatch({ type: Constant.MENUS_BY_CATEGORY_REQUEST, payload: { categoryId } });
    return Api.getMenus('', categoryId).then(payload => {
      dispatch({ type: Constant.MENUS_BY_CATEGORY_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.MENUS_BY_CATEGORY_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getMenu = id => {
  return (dispatch) => {
    dispatch({ type: Constant.MENU_GET_REQUEST });
    return Api.getMenu(id).then(payload => {
      dispatch({ type: Constant.MENU_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.MENU_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}
