import * as Constant from './constants'
import * as Api from '../apis'

export const postAddress = (address, latitude, longitude) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ADDRESS_POST_REQUEST });

    const input = {
      address,
      name: address,
      latitude: parseFloat(latitude.toFixed(10)),
      longitude: parseFloat(longitude.toFixed(10)),
    }

    const { session, shippingInfo } = getState();
    if (!session.user) {
      dispatch({ type: Constant.ADDRESS_POST_SUCCESS, payload: input });
      return Promise.resolve(input);
    }
    
    return Api.postAddress(shippingInfo.address && shippingInfo.address.id, input)
      .then(payload => {
        dispatch({ type: Constant.ADDRESS_POST_SUCCESS, payload });
        dispatch(getCollaboration(payload.latitude, payload.longitude));
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.ADDRESS_POST_FAILED, message });
        return Promise.reject(message);
      });
  }
}

export const getAddresses = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ADDRESSES_GET_REQUEST });
    return Api.getAddresses()
      .then(payload => {
        dispatch({ type: Constant.ADDRESSES_GET_SUCCESS, payload });
        const address = payload[0];
        const { shippingInfo } = getState();
        let tenant = shippingInfo.tenantId;
        if (address) {
          if (tenant === undefined) {
            dispatch(getCollaboration(address.latitude, address.longitude));
          } else { dispatch(getCollaboration(address.latitude, address.longitude, tenant)); }
        }
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.ADDRESSES_GET_FAILED, message });
        return Promise.reject(message);
      });
  }
}

export const getShippingPrice = (lat, lng, price) => {
  return dispatch => {
    return Api.getShippingPrice(lat, lng, price).then(payload => {
      dispatch({ type: Constant.SET_SHIPPING_PRICE, payload });
    });
  }
}

export const getPriceList = () => {
  return dispatch => {
    return Promise.all([
      Api.getFixedPriceList(),
      Api.getPriceList()
    ]).then(([payload1, payload2]) => {
      dispatch({ type: Constant.SET_SHIPPING_FIXED_PRICE_LIST, payload: payload1 });
      dispatch({ type: Constant.SET_SHIPPING_PRICE_LIST, payload: payload2 });
    });
  }
}

export const setTenant = (id) => ({ type: Constant.SET_TENANT, payload: id })

export const getCollaboration = (lat, lng) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.COLLABORATION_GET_REQUEST });
    const { shippingInfo } = getState();
    let getCollab;
    let tenant = shippingInfo.tenantId;
    if (!tenant) {
      getCollab = Api.getCollaboration(lat, lng)
    } else {
      getCollab = Api.getCollaboration(lat, lng, tenant)
    }
    return getCollab
    .then(payload => {
      dispatch({ type: Constant.COLLABORATION_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.COLLABORATION_GET_FAILED, message });
      return Promise.reject(message);
    });
  }
}

export const setShippingInfo = (payload) => ({
  type: Constant.SET_SHIPPING_INFO,
  payload
});
