import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import qs from 'qs';
import logo from '../../assets/images/LogoMorwithLetter.png';

const useStyles = makeStyles((theme) => ({
  flexCenter:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '25px 0 25px 0',
  },
  cardLogin: {
    width: '100%',
    minHeight: '560px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.7) 0%,  rgba(255,255,255,0.7) 18%,  rgba(208,241,255,0.7) 145%)',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    animation: '$card-show 0.75s ease 1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '512px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  logoImg:{
    width: 'auto',
    height: '70px',
    objectFit: 'cover',
    [theme.breakpoints.down(500)]: {
      height: '65px',
    },
  },
  permissionContainer: {
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  lineButton: {
    background: '#00C300',
    color: 'white',
    borderRadius: 5,
    width: 300,
    height: 50,
    fontSize: 16,
    '&:hover': {
      background: '#10b310',
    },
  },
  loginText: {
    fontSize: 18,
    padding: '10px 0px',
    margin: 0,
  },
}))

export default ({}) => {
  const classes = useStyles();

  const login = () => {
    console.log(window.location.pathname, 'prevPath');
    const response_type = "code";
    const client_id = process.env.REACT_APP_LINE_CLIENT_ID;
    const redirect_uri = `${window.location.origin}/lineLoginCallback`;
    const state = Math.random().toString(36).substring(7);
    const scope = "openid profile";
    const params = {
      response_type,
      client_id,
      redirect_uri,
      state,
      scope
    }
    window.location = `https://access.line.me/oauth2/v2.1/authorize?${qs.stringify(params)}`;
  }

  return (
    <div className={classes.flexCenter} style={{ height: '100vh' }}>
      <Grid container spacing={0}
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={5} md={5} xl={5} className={classes.flexCenter}>
          <Card variant="outlined" className={classes.cardLogin}>
            <CardContent style={{height:'100%'}}>
              <Box textAlign="center" className={classes.cardContent}>
                <div className={classes.permissionContainer}>
                  <img className={classes.logoImg} src={logo} alt='' />
                </div>
                <p className={classes.loginText}>กรุณาเข้าสู่ระบบ</p>
                <Button onClick={login} className={classes.lineButton}>
                  เข้าสู่ระบบผ่านไลน์
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}