import { useSelector } from 'react-redux';
import { createUsersSelector } from '../../reducers/users';
import { isSameDate } from '../../helpers/date';
import { getMessageBody } from '../../helpers/messages';
import TextMessage from './TextMessage';
import DateMessage from './DateMessage';
import ShoppingMessage from './ShoppingMessage';
import VideoCallMessage from './VideoCallMessage';

export default ({ messages, session, handleVideoCall }) => {
  const { descriptorById } = useSelector(createUsersSelector);
  const { twilio, user } = session;
  const isPharmacist = user.user_type === 'pharmacist';

  return (
    <>
      {
        messages.map((message, index, array) => {
          const author = descriptorById[message.author];
          let dateElem = <DateMessage date={message.dateCreated} />;
          const prevMsg = array[index - 1];
          if (prevMsg && isSameDate(prevMsg.dateCreated, message.dateCreated)) {
            dateElem = null;
          }
          let msg = getMessageBody(message);
          let msgElem;
          if (msg.type === 'text') {
            msgElem = (
              <TextMessage
                message={msg}
                date={message.dateCreated}
                author={author}
                self={message.author === twilio.identity}
              />
            );
          } else if (msg.type === 'catalog') {
            msgElem = (
              <ShoppingMessage
                message={msg}
                date={message.dateCreated}
                author={author}
                self={message.author === twilio.identity}
                showBuy={!isPharmacist}
              />
            );
          } else if (msg.type === 'videocall') {
            msgElem = (
              <VideoCallMessage
                message={msg}
                date={message.dateCreated}
                author={author}
                self={message.author === twilio.identity}
                onClick={handleVideoCall}
              />
            )
          } else {
            msgElem = <p>{JSON.stringify(msg)}</p>
          }
          return (
            <div key={message.index}>
              {dateElem}
              {msgElem}
            </div>
          )
        })
      }
    </>
  )
}
