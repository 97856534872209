import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotions, removePromotion } from '../../actions/promotion';
import { Chip, Link, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Table from '../Table';
import Cell from '../Cell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import PromotionListDialog from '../PromotionListDialog';
import { createLoadingSelector } from '../../reducers/api';
import { createPromotionsSelector } from '../../reducers/promotions';

export default ({ filter, readOnly }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector(['promotion/get']));
  const promotionList = useSelector(createPromotionsSelector);
  const shippingInfo = useSelector(state => state.shippingInfo);
  const currentPromotion = shippingInfo.promotionId;
  const promotion = useSelector(state => state.promotions.byId[currentPromotion]);
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    dispatch(getPromotions());
  }, []);

  let promotions = promotionList || [];

  const handleClickOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleDelete = () => {
    dispatch(removePromotion(currentPromotion));
  }

  return (
    <Table>
      {
        !readOnly && (
          <PromotionListDialog
            open={modal}
            onClose={handleClose}
            isLoading={isLoading}
            promotionList={promotionList}
            filter={filter}
          />
        )
      }
      <TableBody>
        <TableRow>
          <Cell>โปรโมชั่น</Cell>
          <Cell align="right">
            {promotion ? (
              <span>{promotion.promotion?.name} <IconButton onClick={handleDelete} size="small"><Delete fontSize="small"/></IconButton></span>
            ) :
              <Link color="secondary" onClick={handleClickOpen}>
                เลือก
              </Link>
            }
          </Cell>
        </TableRow>
      </TableBody>
    </Table>
  )
}