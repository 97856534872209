import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const descriptorById = (state = {}, action) => {
  switch(action.type) {
    case Constants.USERS_DESCRIPTOR_GET_SUCCESS: {
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.identity]: item,
        }
      }, state);
    }
    default: return state;
  }
}

export default combineReducers({
  descriptorById,
});

export const createUserDescriptorSelector = (state, id) => state.users.descriptorById[id];
export const createUsersSelector = (state) => state.users;

