import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removeCreditCard, getCreditCards } from '../../actions/creditCards';
import { makeStyles } from '@material-ui/core/styles';
import { PaymentType, PAYMENTS } from '../../helpers/constants';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SlideDialog from '../SlideDialog';
import IconButton from '@material-ui/core/IconButton';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(theme => ({
  image: {
    height: 24
  },
}));

const filterPayment = (val, source) => {
  let accepted = [
    PaymentType.PROMPTPAY,
  ];
  if (source === 'ios' || source === 'android') {
    accepted = [
      PaymentType.MOBILE_BANK_BAY,
      PaymentType.MOBILE_BANK_BBL,
      PaymentType.MOBILE_BANK_KBANK,
      PaymentType.MOBILE_BANK_SCB,
      PaymentType.PROMPTPAY,
    ];
  }
  return accepted.includes(val.type);
}

const PaymentListSlideDialog = ({ open, title, onClose, creditCards, currentPayment, onSelectPayment }) => {
  const classes = useStyles();
  const [confirmModal, setConfirmModal] = useState(false);
  const [removeItem, setRemoveItem] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { source } = useSelector(state => state.session);

  function showRemoveConfirmModal(removeItem) {
    setRemoveItem(removeItem);
    setConfirmModal(true);
  }

  async function confirmRemoveCard() {
    await dispatch(removeCreditCard(removeItem.id));
    await dispatch(getCreditCards());
    setConfirmModal(false);
    setRemoveItem({});
  }

  return (
    <SlideDialog
      open={open}
      title={title}
      onClose={onClose}
    >

      <ConfirmDialog
        open={confirmModal}
        // swap confirm and close function because of label
        onClose={confirmRemoveCard}
        onConfirm={() => setConfirmModal(false)}
        okText={<span style={{ color: '#52ad52' }}>ยกเลิก</span>}
        cancelText={<span style={{ color: '#ef5d5a' }}>ใช่</span>}
        content={(
          <span>
            ต้องการลบ <span style={{ color: '#35aaff', fontWeight: 'bold' }}>{`${removeItem.brand} ${removeItem.last_digits}`}</span> ใช่หรือไม่
          </span>
        )}
        title=""
      />
      <List>
        {
          PAYMENTS.filter(val => filterPayment(val, source)).map((val, index) => {
            return (
              <>
                <ListItem key={index} button onClick={() => onSelectPayment(val.type)}>
                  <ListItemIcon>
                    <img src={require(`../../assets/creditcards/${val.img}`).default} className={classes.image} />
                  </ListItemIcon>
                  <ListItemText primary={val.title} />
                  {
                    currentPayment === val.type && (
                      <ListItemSecondaryAction>
                        <DoneIcon />
                      </ListItemSecondaryAction>
                    )
                  }
                </ListItem>
                <Divider />
              </>
            )
          })
        }
        {
          creditCards.map(creditCard =>
            <Fragment key={creditCard.id}>
              <ListItem button onClick={() => onSelectPayment(creditCard.id)}>
                <ListItemIcon>
                  <img className={classes.image} src={require(`../../assets/creditcards/${creditCard.brand.toLowerCase()}.png`).default} />
                </ListItemIcon>
                <ListItemText primary={`${creditCard.brand} ${creditCard.last_digits}`} />
                {
                  currentPayment && currentPayment.id === creditCard.id && (
                    <IconButton>
                      <DoneIcon />
                    </IconButton>
                  )
                }
                {/* <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => showRemoveConfirmModal(creditCard)}>
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </ListItemSecondaryAction> */}
              </ListItem>
              <Divider />
            </Fragment>
          )
        }
        <ListItem button onClick={() => history.push('/addCard')}>
          <ListItemText primary="เพิ่มบัตร Credit/Debit" />
        </ListItem>
        <Divider />
      </List>
    </SlideDialog>
  );
}

export default PaymentListSlideDialog;
