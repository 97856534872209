import React from 'react';
import SkeletonLoader from '../SkeletonLoader';
import NotificationEmptyState from '../NotificationEmptyState';

export default ({ loading, empty, emptyText = 'ไม่พบรายการ', children }) => {
  if (empty) {
    return (
      <NotificationEmptyState compact>
        <h3>{emptyText}</h3>
      </NotificationEmptyState>
    )
  }
  if (loading) {
    return <SkeletonLoader />;
  }
  return children;
}