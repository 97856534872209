import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { becomeLiff, becomeAppToken, mockPharmacist, becomeSource, become } from './actions/session';
import { ThemeProvider } from '@material-ui/styles';
import { getUrlParams } from './helpers/location';
import CssBaseline from '@material-ui/core/CssBaseline';
import createStore from './stores/createStore';
import theme from './theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'react-credit-cards/es/styles-compiled.css';

const store = createStore();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

const query = getUrlParams(window.document.location.search)

if (!!query.debug) {
  const VConsole = require('vconsole');
  new VConsole();
}

if (process.env.REACT_APP_TOKEN_TEST) {
  query.appToken = query.appToken || process.env.REACT_APP_TOKEN_TEST;
}

console.log(query);
// Valid query - useLiff (deprecated), liffId, useFb, appToken, mockPharmacist

if (!!query.liffId) {
  // Line LIFF
  store.dispatch(becomeLiff(query.liffId));
} else if (!!query.useFb) {
  // FB Messenger Platform
  ((d, s, id) => {
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'Messenger');
  window.extAsyncInit = () => {
    // store.dispatch(becomeFb());
  };
} else if (!!query.appToken) {
  store.dispatch(becomeAppToken(query.appToken));
} else if (!!query.mockPharmacist) { // Mock pharmacist has higher priority
  if (query.mockPharmacist !== "true") {
    store.dispatch(mockPharmacist(query.mockPharmacist));
  } else {
    store.dispatch(mockPharmacist());
  }
} else {
  store.dispatch(become());
}

if (!!query.source) {
  store.dispatch(becomeSource(query.source));
}