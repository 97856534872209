import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getSymptom, pharmacistCloseCase } from '../../actions/symptoms';
import { getPharmacists } from '../../actions/pharmacists';
import { getChannel } from '../../actions/channels';
import { getChannelUsers } from '../../actions/users';
import { getChannelMessages, createMessage, createVideoMessage } from '../../actions/messages';
import { createChannelByIdSelector } from '../../reducers/channels';
import { createLoadingSelector } from '../../reducers/api';
import { getMessageBody } from '../../helpers/messages';
import useOfficeHour from '../../hooks/useOfficeHour';
import TextInput from '../../components/Chat/TextInput';
import MessageList from '../../components/Chat/MessageList';
import UsersBar from '../../components/Chat/UsersBar';
import MuiAlert from '@material-ui/lab/Alert';
import SimpleListDialog from '../../components/SimpleListDialog';
import TypingIndicator from '../../components/TypingIndicator';
import ConfirmDialog from '../../components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      maxWidth: '100%',
    },
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  messages: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    flex: '1 1 0%',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      maxWidth: '100%',
    },
  },
  action: {
    position: 'relative',
    flex: '0 1 auto',
    display: 'flex',
    alignContent: 'flex-end',
    '& > *': {
      minWidth: 0,
    },
  },
  typing: {
    color: 'grey',
    position: 'absolute',
    textAlign: 'center',
    top: -25,
    width: '100%'
  }
}));

const ConversationPage = () => {
  const msgRef = React.useRef();
  const [showDialog, setShowDialog] = React.useState(false);
  const [videoCallDialog, setVideoCallDialog] = React.useState();
  const [isTyping, setIsTyping] = React.useState();
  const officeHour = useOfficeHour();
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const messages = useSelector(state => state.messages);
  const channel = useSelector(createChannelByIdSelector(params.channelId));
  const loading = useSelector(createLoadingSelector(['channel/get']));
  const { channelId } = params;

  const isPharmacist = session.user.user_type !== 'normal';

  const scroll = React.useCallback(() => {
    if (msgRef.current) {
      msgRef.current.scrollTop = msgRef.current.scrollHeight;
    }
  }, [msgRef]);
  React.useEffect(() => {
    dispatch(getChannel(channelId)).then(ch => {
      dispatch(getSymptom(!!isPharmacist, ch.uniqueName));
      dispatch(getChannelMessages(ch));
      dispatch(getChannelUsers(ch));
    });
    dispatch(getPharmacists());
  }, [params]);
  React.useEffect(() => {
    if (channel) {
      channel.on('messageAdded', (msg) => {
        const m = getMessageBody(msg);
        if (m.type === 'videocall') {
          setVideoCallDialog(m);
        }
        dispatch(getChannelMessages(channel));
      });
      channel.on('memberJoined', function () {
        dispatch(getChannel(channelId));
      });
      //set up the listener for the typing started Channel event
      channel.on('typingStarted', function (member) {
        //process the member to show typing
        setIsTyping(member);
      });
      //set  the listener for the typing ended Channel event
      channel.on('typingEnded', function (member) {
        //process the member to stop showing typing
        setIsTyping();
      });
    }
    // return () => {
    //   channel.removeAllListeners();
    // }
  }, [channel]);
  React.useEffect(() => {
    if (messages) {
      scroll();
      readMessage();
    }
  }, [messages]);

  const readMessage = () => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      channel.updateLastReadMessageIndex(lastMessage.index)
    }
  }

  const onSubmit = (res) => {
    dispatch(
      createMessage(channel, JSON.stringify(res))
    );
  }
  const onClickOption = (res) => {
    setShowDialog(true);
  }

  const onSelectOption = (index) => {
    setShowDialog(false);
    if (index === 0) {
      history.push(`/menu?fromChannel=${channel.sid}`);
    } else if (index === 1) {
      dispatch(createVideoMessage(channel));
    } else if (index === 2) {
      dispatch(pharmacistCloseCase(channel.uniqueName)).then(() => {
        history.replace('/listChannel');
      });
    }
  }

  const onHandleVideoDialog = (confirmed) => {
    if (confirmed && videoCallDialog) {
      if (session.source === 'liff') {
        window.open(`${videoCallDialog.value.url}&openExternalBrowser=1`);
      } else {
        window.open(videoCallDialog.value.url);
      }
    }
    setVideoCallDialog(); // Close dialog anyway
  }

  if (!channel || loading) return null;

  let alertBox;
  if (officeHour) {
    if (!messages.length) {
      alertBox = <MuiAlert severity="info">มีอาการอย่างไรสามารถปรึกษาเภสัชได้เลยค่ะ</MuiAlert>;
    }
  } else {
    alertBox = <MuiAlert severity="info">ขณะนี้อยู่นอกเหนือเวลาให้บริการ พนักงานจะตอบกลับอีกครั้ง ตั้งแต่เวลา 08:00 น. ถึงเวลา 18:00 น.</MuiAlert>;
  }

  return (
    <div className={classes.container}>

      <ConfirmDialog
        open={videoCallDialog}
        okText="ตกลง"
        content="คุณต้องการจะสนทนาแบบวิดีโอคอลหรือไม่?"
        title="วิดีโอคอล"
      />
      <ConfirmDialog
        open={videoCallDialog}
        onClose={() => onHandleVideoDialog(false)}
        onConfirm={() => onHandleVideoDialog(true)}
        okText="ตกลง"
        cancelText="ยกเลิก"
        content="คุณต้องการจะสนทนาแบบวิดีโอคอลหรือไม่?"
        title="วิดีโอคอล"
      />
      <SimpleListDialog
        title="กรุณาเลือก"
        open={showDialog}
        disabledItems={process.env.REACT_APP_VIDEO_CALL == 'true' ? [] : [1]}
        items={['เลือกรายการยา', 'วิดีโอคอล', 'ปิดเคสนี้']}
        onClose={() => setShowDialog(false)}
        onItemClick={onSelectOption}
      />
      <UsersBar users={channel._participants} channel={channel} />
      {alertBox}
      <div className={classes.messages} ref={msgRef}>
        <MessageList
          messages={messages}
          session={session}
          handleVideoCall={setVideoCallDialog}
        />
      </div>
      <div className={classes.action}>
        {
          isTyping && (
            <div className={classes.typing}>
              <span>Someone is typing</span>
              <TypingIndicator />
            </div>
          )
        }
        <TextInput
          showOption={isPharmacist}
          onSubmit={onSubmit}
          onSelect={onClickOption}
          onTyping={() => channel.typing()}
        />
      </div>
    </div>
  )
}

export default ConversationPage;
