import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';

export default ({ type, loading, icon, children, ...rest }) => {

  let Component = Button;
  if (type === 'fab') Component = Fab;

  return (
    <Component {...rest} disabled={loading}>
      {
        loading ? <CircularProgress size={18} color="secondary"/> : (icon || null)
      }
      {
        children && <>&nbsp;</>
      }
      {children}
    </Component>
  );
};
