import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '../../helpers/utility';
import { getUrlParams } from '../../helpers/location';
import { createChannelByIdSelector } from '../../reducers/channels';
import { createInitialSelected, createMenusSelector, createCurrentQuery, createSelectedMenusToMessageSelector } from '../../reducers/menus';
import { searchMenus, removeMenu } from '../../actions/menus';
import { createCategoriesSelector } from '../../reducers/categories';
import { getCategories } from '../../actions/categories';
import { getPriceList, setTenant } from '../../actions/shippingInfo';
import { createLoadingSelector } from '../../reducers/api';
import { createMessage } from '../../actions/messages';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import MessageIcon from '@material-ui/icons/Message';
import MenuItemList from '../../components/MenuItemList';
import Button from '../../components/Button';
import AutoComplete from '../../components/AutoComplete';
import SelectLocation from '../../components/SelectLocation';
import ProductAddToCartDialog from '../../components/ProductAddToCartDialog';
import PromotionHomeDialog from '../../components/PromotionHomeDialog';
import DrugStoreAlertTermDialog from '../../components/DrugStoreAlertTermDialog';
import ConsentNews from '../../components/ConsentNews';

const useStyles = makeStyles(theme => {
  return {
    filter: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      "&> div": {
        flex: 1
      }
    },
    root: {
      padding: theme.spacing(1, 1),
      opacity: 0.9,
    },
    contactButton: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    orderNowButton: {
      width: 160,
      color: 'white',
      fontSize: '1.1em',
      backgroundColor: theme.palette.primary.main,
    },
    orderNow: {
      position: 'absolute',
      bottom: theme.spacing(2),
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    locationHead: {
      position: 'relative',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    locationA: {
      paddingLeft: 26,
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    }
  }
});

const MenuPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showProduct, setShowProduct] = React.useState();
  const [categoryId, setCategory] = React.useState();
  const [query, setQuery] = React.useState();
  const [page, setPage] = React.useState(1);
  const isLoadingMenus = useSelector(createLoadingSelector(['menus/get', 'menus/search']));
  // const session = useSelector(state => state.session);
  const shippingInfo = useSelector(state => state.shippingInfo);
  const currentQuery = useSelector(createCurrentQuery);
  const selected = useSelector(createInitialSelected);
  const menus = useSelector(createMenusSelector);
  const pageQuery = getUrlParams(history.location.search);
  const channel = useSelector(createChannelByIdSelector(pageQuery.fromChannel));
  const selectedMenus = useSelector(createSelectedMenusToMessageSelector);
  const categories = useSelector(createCategoriesSelector);
  const tenantId = pageQuery.tenant;
  const [showInitialPopupDialog, setShowInitialPopupDialog] = React.useState(true);
  const [openPromotionDialog, setOpenPromotionDialog] = React.useState(false);

  React.useEffect(() => {
    dispatch(getCategories());
    dispatch(getPriceList());
    dispatch(setTenant(tenantId));
  }, []);

  React.useEffect(() => {
    if (shippingInfo.address && shippingInfo.collaboration) {
      resetPage();
    }
    if (query && query.length > 1) {
      window.logEvent('search', { search_term: query });
    }
  }, [query, shippingInfo.collaboration]);

  React.useEffect(() => {
    if (shippingInfo.address && shippingInfo.collaboration) {
      resetPage();
    }
  }, [categoryId, shippingInfo.collaboration]);

  const resetPage = () => {
    setPage(1);
    dispatch(searchMenus(query, categoryId));
  }

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  }

  const handleAddMenu = (id) => {
    // dispatch(addMenu(id));
    window.logEvent('select_content', { content_type: 'drug', item_id: id + '' });
    setShowProduct(id);
  }
  const handleRemoveMenu = (id) => {
    dispatch(removeMenu(id));
  }
  const onClickLoadMore = () => {
    dispatch(searchMenus(query, categoryId, page + 1));
    setPage(page + 1);
  };

  const onInitialPopupDialogClose = (e) => {
    setShowInitialPopupDialog(false);
    setOpenPromotionDialog(true);
  }

  const onSubmit = () => {
    if (channel) {
      // Pharmacist journey: Select drugs for customers
      const message = {
        "type": "catalog",
        "value": selectedMenus
      }
      dispatch(createMessage(channel, JSON.stringify(message)))
      history.goBack();
    } else {
      history.push('/otp?noSkip=1&returnUri=/checkout');
    }
  }

  return (
    <>
      <ProductAddToCartDialog
        productId={showProduct}
        open={!!showProduct}
        onClose={() => { setShowProduct(null) }}
        update={false}
      />
      <ConsentNews />
      <DrugStoreAlertTermDialog onClose={onInitialPopupDialogClose} />
      <PromotionHomeDialog startOpen={openPromotionDialog} />
      <Box pb={8}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.root} square>
              <div className={classes.locationHead}>
                <LocationOnIcon style={{ position: 'absolute', top: -2 }} />
                <div className={classes.locationA}>
                  <SelectLocation force />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root} square>
              <div className={classes.filter}>
                <Typography>
                  ค้นหา
                </Typography>
                <Box my={1}>
                  <AutoComplete
                    placeholder="eg. Sonafi..."
                    onSelect={debounce(setQuery, 500)}
                  />
                </Box>
              </div>
              <div className={classes.filter}>
                <Typography>
                  หมวดหมู่
                </Typography>
                <Box my={1}>
                  <Select
                    native
                    fullWidth
                    value={categoryId}
                    onChange={handleCategoryChange}
                  >
                    <option value="" >ทั้งหมด</option>
                    {
                      categories.map(item => {
                        return (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </Select>
                </Box>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root} square>
              <Typography variant="h6" component="h6">
                รายการทั้งหมด
              </Typography>
              <MenuItemList
                menus={menus}
                selected={selected}
                addMenu={handleAddMenu}
                removeMenu={handleRemoveMenu}
                hasNext={!!currentQuery.next}
                loading={isLoadingMenus}
                onLoadMore={onClickLoadMore}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {
        !!selected.length && (
          <div className={classes.orderNow}>
            <Button
              className={classes.orderNowButton}
              type="fab"
              variant="extended"
              onClick={onSubmit}
            >
              ยืนยัน ({selected.length} รายการ)
            </Button>
          </div>
        )
      }
      <Link
        className={classes.contactButton}
        to="/createChannel"
      >

        <Fab
          size="large"
          color="primary"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <MessageIcon />
            <span>เภสัช</span>
          </Box>
        </Fab>
      </Link>
    </>
  )
}

export default MenuPage;
