import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { displayCurrency } from '../../helpers/currency';
import { createPriceByDistance } from '../../reducers/shippingInfo';
import useCollaboration from '../../hooks/useCollaboration';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function displayPromotionText(minPrice) {
  const str = ['สั่งซื้อครบ'];
  str.push(displayCurrency(minPrice));
  str.push('ส่งฟรี');
  return str.join(' ');
}

const useStyles = makeStyles(theme => ({
  alertPromotion: {
    fontSize: '1.5em',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    position: 'sticky',
    top: 0,
    color: 'white',
    background: 'rgb(227,90,90)',
    background: 'linear-gradient(90deg, rgba(227,90,90,1) 0%, rgba(212,18,18,1) 100%)',
  }
}));

const PromotionAlertCheckout = () => {
  const classes = useStyles();
  const priceByDistance = useSelector(createPriceByDistance)
  // const session = useSelector(state => state.session);
  const shippingInfo = useSelector(state => state.shippingInfo);
  const collaboration = useCollaboration(shippingInfo.address);
  const deliveryPrice = shippingInfo.deliveryPrice;
  
  if (priceByDistance && deliveryPrice !== 0 && collaboration?.tenant?.id === 1) { // Hard code check for drugsquare
    return (
      <Alert className={classes.alertPromotion} severity="success" icon={false}>
        {displayPromotionText(priceByDistance["minimum_price"])}
      </Alert>
    )
  }
  return null;
}

export default PromotionAlertCheckout;
