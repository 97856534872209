import { Box, Grow } from '@material-ui/core';
import { renderChatDate } from '../../helpers/date';

export default ({ date }) => {

  // const l = self ? '20%' : 0;
  // const r = self ? 0 : '20%';
  // const bgcolor = self ? 'primary.main' : 'background.paper';
  // const color = self ? 'primary.contrastText' : 'text.primary';
  const justifyContent = 'center';

  return (
    <Grow in>
      <Box
        flex="0 0 auto"
        display="flex"
        justifyContent="center"
      >
        <small>{renderChatDate(date)}</small>
      </Box>
    </Grow>
  )
}