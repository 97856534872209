import React from 'react';
import { useDispatch } from 'react-redux';
import { setPromotion } from '../../actions/promotion';
import EnterPromotionCode from '../EnterPromotionCode';
import SlideDialog from '../SlideDialog';
import PromotionList from '../PromotionList';

export default ({ open, onClose, isLoading, promotionList, filter }) => {
  const dispatch = useDispatch();

  const onClickPromotion = (id) => {
    dispatch(setPromotion(id));
    onClose();
  }

  return (
    <SlideDialog
      open={open}
      onClose={onClose}
    >
      <EnterPromotionCode />
      <PromotionList onSelectPromotion={onClickPromotion} filter={filter} isLoading={isLoading} promotionList={promotionList} readOnly={false} />
    </SlideDialog>
  )
}