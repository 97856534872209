import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default ({
  open, onClose, onConfirm, okText, content, title = '', disableBackdropClick = false
}) => {
  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth='lg'
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="secondary">
          {okText || 'ตกลง'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}