import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import LocationIcon from '@material-ui/icons/LocationOn';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  marker: {
    color: theme.palette.primary.main,
  },
}));

const MapMarker = memo(({ placeholder }) => {
  const classes = useStyles();
  return (
    <div className={classes.marker}>
      {placeholder}
    </div>
  );
});

export default memo(({ center = {}, noMarker, title, ...rest }) => {
  return (
    <div
      style={{
        height: '100%',
        position: 'relative'
      }}
    >
      {
        title && (
          <a
            style={{
              position: 'absolute',
              zIndex: 1,
              right: 10,
              top: 10
            }}
            href={`https://www.google.com/maps/search/?api=1&query=${center.latitude},${center.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>{title}</Button>
          </a>
        )
      }
      <GoogleMapReact
        {...rest}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY, libraries: 'places' }}
        defaultZoom={14}
        center={{ lat: center.latitude, lng: center.longitude }}
        options={{
          scrollwheel: false,
          fullscreenControl: false,
          scaleControl: true,
        }}
      >
        {
          !noMarker && (
            <MapMarker
              lat={center.latitude}
              lng={center.longitude}
              placeholder={<LocationIcon fontSize="large"/>}
            />
          )
        }
      </GoogleMapReact>
    </div>
  );
});
