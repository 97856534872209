import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { createAllPharmacistSelector } from '../../reducers/pharmacists';
import { createAllSymptomSelector, createSymptomByChatRoomSelector } from '../../reducers/symptoms';
import { createUsersSelector } from '../../reducers/users';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import InfoDialog from '../InfoDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 45,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1em'
  },
  memberGroup: {
    fontSize: '0.9em',
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const ListItemTextContent = ({ title, description }: { title?: string, description?: string | React.ReactElement }) => (
  <>
    {title && <Typography component="p" variant="body2" color="textPrimary" >{title}</Typography>}
    {description && <Typography variant="body2" component="p">{description}</Typography>}

  </>
)

export default ({ users, channel }: { users: Map<string, any>, channel: any }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [showSymptomDialog, setShowSymptomDialog] = React.useState(false);
  const pharmacists = useSelector(createAllPharmacistSelector);
  const symptom = useSelector(state => createSymptomByChatRoomSelector(state, channel.uniqueName));
  const { descriptorById } = useSelector(createUsersSelector);

  const clickShowInfo = (e: any) => {
    e.preventDefault();
    setShowInfoDialog(true);
    setAnchorEl(null);
  }

  const clickShowSymptom = (e: any) => {
    e.preventDefault();
    setShowSymptomDialog(true);
    setAnchorEl(null);
  }

  const clickMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (!users) return null;

  const usersArray = Array.from(users.values());
  const pharmacistUser = pharmacists.find(p => {
    return usersArray.find(user => p.unique_id === user.identity);
  });
  const patientUser = symptom && descriptorById[symptom.customer?.unique_id];

  return (
    <div className={classes.container}>
      <InfoDialog
        open={showInfoDialog}
        okText="ตกลง"
        onClose={() => setShowInfoDialog(false)}
        onConfirm={() => setShowInfoDialog(false)}
        disableBackdropClick={true}
        content={pharmacistUser && (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src={pharmacistUser.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={`ภก. ${pharmacistUser.first_name} ${pharmacistUser.last_name}`}
              secondary={
                <div style={{ overflow: 'hidden' }}>
                  <ListItemTextContent description={`เลขที่ใบอนุญาต - ${pharmacistUser.license_number}`}/>
                  <ListItemTextContent title="ประวัติ" description={pharmacistUser.bio}/>
                  <ListItemTextContent title="การศึกษา" description={pharmacistUser.education}/>
                </div>
              }
            />

          </ListItem>
        )}
      />
      <InfoDialog
        open={showSymptomDialog}
        okText="ตกลง"
        onClose={() => setShowSymptomDialog(false)}
        onConfirm={() => setShowSymptomDialog(false)}
        disableBackdropClick={true}
        content={
          patientUser && (
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar src={patientUser.attributes?.line_image} />
              </ListItemAvatar>
              <ListItemText
                primary={patientUser.friendlyName}
                secondary={
                  <div style={{ overflow: 'hidden' }}>
                    <ListItemTextContent title="เบอร์โทร" description={<a href={`tel:${patientUser.attributes?.phone}`}>{patientUser.attributes?.phone}</a>}/>
                    <ListItemTextContent title="ที่อยู่" description={symptom.address?.address}/>
                    <ListItemTextContent title="อาการเบื้องต้น" description={symptom.symptom?.name}/>
                    <ListItemTextContent title="ระยะเวลา" description={symptom.period}/>
                    <ListItemTextContent title="ข้อมูลเพิ่มเติม" description={symptom.detail}/>
                  </div>
                }
              />
            </ListItem>
          )
        }
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
      >
        {
          pharmacistUser && (
            <MenuItem onClick={clickShowInfo}>ข้อมูลเภสัช</MenuItem>
          )
        }
        {
          patientUser && (
            <MenuItem onClick={clickShowSymptom}>ข้อมูลผู้ป่วย</MenuItem>
          )
        }
      </Menu>
      <div className={classes.memberGroup}>
        <Button
          variant="outlined"
          onClick={clickMenu}
        >
          ดูข้อมูล
        </Button>
      </div>
      <div className={classes.memberGroup}>
        {
          descriptorById && (
            <AvatarGroup max={2}>
              {
                usersArray.map((user, index) => {
                  const descriptor = descriptorById[user.identity];
                  if (!descriptor) {
                    return null;
                  }
                  const { attributes = {} } = descriptor;
                  const img = attributes.data?.profile_image;
                  return <Avatar key={index} alt={descriptor.name} src={img} />;
                })
              }
            </AvatarGroup>
          )
        }
      </div>
    </div>
  )
}