import * as Constant from './constants';
import * as Api from '../apis';

export const postSymptom = (symptom, period, detail) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.SYMPTOM_POST_REQUEST });
    const { shippingInfo } = getState();
    const req = {
      symptom,
      period,
      detail,
      address: shippingInfo.address
    }
    return Api.postSymptom(req).then((payload) => {
      dispatch({ type: Constant.SYMPTOM_POST_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.SYMPTOM_POST_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getSymptoms = (isPharmacist) => {
  return dispatch => {
    dispatch({ type: Constant.SYMPTOMS_GET_REQUEST });
    return Api.getSymptoms(isPharmacist).then((payload) => {
      dispatch({ type: Constant.SYMPTOMS_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.SYMPTOMS_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getSymptom = (isPharmacist, chatroomId) => {
  return dispatch => {
    dispatch({ type: Constant.SYMPTOM_GET_REQUEST });
    return Api.getSymptom(isPharmacist, chatroomId).then((payload) => {
      dispatch({ type: Constant.SYMPTOM_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.SYMPTOM_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const pharmacistCloseCase = (chatroomId) => {
  return dispatch => {
    dispatch({ type: Constant.SYMPTOM_CLOSE_POST_REQUEST });
    return Api.pharmacistCloseCase(chatroomId).then((payload) => {
      dispatch({ type: Constant.SYMPTOM_CLOSE_POST_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.SYMPTOM_CLOSE_POST_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const pharmacistAssign = (chatroomId) => {
  return dispatch => {
    dispatch({ type: Constant.SYMPTOM_ASSIGN_POST_REQUEST });
    return Api.pharmacistAssign(chatroomId).then((payload) => {
      dispatch({ type: Constant.SYMPTOM_ASSIGN_POST_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.SYMPTOM_ASSIGN_POST_FAILED, message });
      return Promise.reject(message);
    })
  }
}
