import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch(action.type) {
    case Constants.CATEGORIES_GET_SUCCESS: {
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state);
    }
    default: return state;
  }
};

const allIds = (state = [], action) => {
  switch(action.type) {
    case Constants.CATEGORIES_GET_SUCCESS: {
      return action.payload.map(item => item.id);
    }
    default: return state;
  }
};

export default combineReducers({
  byId,
  allIds,
});

export const createCategoriesSelector = state => state.categories.allIds.map(id => state.categories.byId[id]);
export const createCategoryByIdSelector = (state, id) => state.categories.byId[id]
