import * as Constant from './constants'
import * as Api from '../apis'
import { createToken } from '../apis/omise'

export const postCreditCard = (name, number, expirationMonth, expirationYear, cvc) => {
  return dispatch => {
    dispatch({ type: Constant.CREDIT_CARD_POST_REQUEST });
    return createToken(name, number, expirationMonth, expirationYear, cvc)
      .then(payload => {
        if (!payload.card.security_code_check) {
          return Promise.reject('Invalid CCV');
        }
        return payload;
      }).then(payload => {
        return Api.postCreditCard(payload.id).then(payload => dispatch(patchCreditCard(payload.id)))
      }).then(payload => {
        dispatch({ type: Constant.CREDIT_CARD_POST_SUCCESS, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.CREDIT_CARD_POST_FAILED, message });
        return Promise.reject(message);
      });
  }
};

export const setPaymentType = (type) => ({ type: Constant.SET_PAYMENT_TYPE, payload: type })
export const setCash = () => ({ type: Constant.SET_CASH, payload: null })
export const setPromptpay = () => ({ type: Constant.SET_PROMPTPAY, payload: null })

export const patchCreditCard = id => {
  return dispatch => {
    dispatch({ type: Constant.CREDIT_CARD_PATCH_REQUEST });
    return Api.patchCreditCard(id, { is_default: true })
      .then(payload => {
        dispatch({ type: Constant.CREDIT_CARD_PATCH_SUCCESS, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.CREDIT_CARD_PATCH_FAILED, message });
        return Promise.reject(message);
      });
  }
}

export const getCreditCards = () => {
  return dispatch => {
    dispatch({ type: Constant.CREDIT_CARDS_GET_REQUEST });
    return Api.getCreditCards().then(payload => {
      dispatch({ type: Constant.CREDIT_CARDS_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CREDIT_CARDS_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const removeCreditCard = id => {
  return dispatch => {
    dispatch({ type: Constant.CREDIT_CARDS_REMOVE_REQUEST });
    return Api.removeCreditCard(id).then(payload => {
      dispatch({ type: Constant.CREDIT_CARDS_REMOVE_SUCCESS, id });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CREDIT_CARDS_REMOVE_FAILED, message });
      return Promise.reject(message);
    })
  }
}