import React from 'react';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const suggestions = [
  // { label: 'Afghanistan' },
  // { label: 'Aland Islands' },
  // { label: 'Albania' },
  // { label: 'Algeria' },
  // { label: 'American Samoa' },
  // { label: 'Andorra' },
  // { label: 'Angola' },
  // { label: 'Anguilla' },
  // { label: 'Antarctica' },
  // { label: 'Antigua and Barbuda' },
  // { label: 'Argentina' },
  // { label: 'Armenia' },
  // { label: 'Aruba' },
  // { label: 'Australia' },
  // { label: 'Austria' },
  // { label: 'Azerbaijan' },
  // { label: 'Bahamas' },
  // { label: 'Bahrain' },
  // { label: 'Bangladesh' },
  // { label: 'Barbados' },
  // { label: 'Belarus' },
  // { label: 'Belgium' },
  // { label: 'Belize' },
  // { label: 'Benin' },
  // { label: 'Bermuda' },
  // { label: 'Bhutan' },
  // { label: 'Bolivia, Plurinational State of' },
  // { label: 'Bonaire, Sint Eustatius and Saba' },
  // { label: 'Bosnia and Herzegovina' },
  // { label: 'Botswana' },
  // { label: 'Bouvet Island' },
  // { label: 'Brazil' },
  // { label: 'British Indian Ocean Territory' },
  // { label: 'Brunei Darussalam' },
];

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.label}
    </MenuItem>
  );
}

function getSuggestions(value = '') {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
      const keep =
        count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function IntegrationAutosuggest({ onSelect, placeholder }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    single: '',
    popper: '',
  });

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue,
    });
    onSelect(newValue);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          // label: 'Category',
          placeholder,
          value: state.single,
          onChange: handleChange('single'),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}