import { displayCurrency } from '../../helpers/currency';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '../Table';
import Cell from '../Cell';


export default ({ deliveryTitle, summary }) => {
  if (!summary) return null;

  return (
    <>
      <h3>สรุป</h3>
      <Table>
        <TableBody>
          <TableRow>
            <Cell colSpan={1}>
              ยอดสั่งซื้อ
            </Cell>
            <Cell colSpan={1} align="right">
              {displayCurrency(summary.total_price)}
            </Cell>
          </TableRow>
          
          {
            summary.delivery_price && (
              <TableRow>
                <Cell colSpan={1}>
                  {deliveryTitle || 'ค่าบริการ'}
                </Cell>
                <Cell colSpan={1} align="right">
                  {displayCurrency(summary.delivery_price)}
                </Cell>
              </TableRow>
            )
          }
          {
            summary.discount_price > 0 && (
              <TableRow>
                <Cell colSpan={1} style={{color: 'red'}}>
                  ส่วนลดค่ายา
                </Cell>
                <Cell colSpan={1} align="right" style={{color: 'red'}}>
                  -{displayCurrency(summary.discount_price)}
                </Cell>
              </TableRow>
            )
          }
          {
            summary.discount_delivery_price > 0 && (
              <TableRow>
                <Cell colSpan={1} style={{color: 'red'}}>
                  ส่วนลด{deliveryTitle || 'ค่าบริการ'}
                </Cell>
                <Cell colSpan={1} align="right" style={{color: 'red'}}>
                  -{displayCurrency(summary.discount_delivery_price)}
                </Cell>
              </TableRow>
            )
          }
          {
            summary.minimum_order_difference > 0 && (
              <TableRow>
                <Cell colSpan={1}>
                  ขั้นต่ำในการชำระเงิน*
                </Cell>
                <Cell colSpan={1} align="right">
                  {displayCurrency(summary.minimum_order_difference)}
                </Cell>
              </TableRow>
            )
          }
          {
            summary.minimum_order_difference > 0 && (
              <span style={{ fontSize: 10 }}>* ส่วนต่างจะถูกชาร์จเมื่อราคาสินค้ารวมค่าส่งน้อยกว่า 20 บาท</span>
            )
          }
          {
            summary.grand_total_price >= 0 && (
              <TableRow>
                <Cell colSpan={1}>
                  ยอดรวม
                </Cell>
                <Cell colSpan={1} align="right">
                  {displayCurrency(summary.grand_total_price)}
                </Cell>
              </TableRow>
            )
          }
          {
            summary.prerequisite && (
              <TableRow>
                <Cell colSpan={1}>
                  <small>*หมายเหตุ {summary.prerequisite}</small>
                </Cell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </>
  );
}

