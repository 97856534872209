import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

import SukhumvitSet from './assets/fonts/sukhumvit/SukhumvitSet-Medium.ttf';
import GothamMedium from './assets/fonts/gotham/GothamMedium.ttf';
import GothamLight from './assets/fonts/gotham/GothamLight.ttf';

const sukhumvit = {
  fontFamily: 'Sukhumvit',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${SukhumvitSet}) format('truetype')`,
  unicodeRange: 'U+0E00-U+0E7F',
};

const gotham = {
  fontFamily: 'Gotham',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${GothamLight}) format('truetype')`,
  unicodeRange: 'U+41-5A, U+61-7A, U+30-39, U+A0',
};

const gothamBold = {
  fontFamily: 'Gotham',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${GothamMedium}) format('truetype')`,
  unicodeRange: 'U+41-5A, U+61-7A, U+30-39, U+A0',
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR || '#3275b5',
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_COLOR || '#59c4f9',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Sukhumvit'
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [sukhumvit, gotham, gothamBold],
      },
    },
  },
});

export default theme;
