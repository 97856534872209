import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {getUrlParams} from '../../helpers/location';
import * as Api from '../../apis';
import navigate from '../../helpers/crossPlatformLink';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const PromptPay = () => {
  const location = useLocation();
  const params = getUrlParams(location.search);
  const { code } = params;
  const { source } = useSelector(state => state.session);

  if (!code) {
    return null;
  }

  const svgString2Image = (svgString, width, height, format) => {
    format = format ? format : 'png';
    var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    var image = new Image();
    image.src = svgData;
    image.onload = function () {
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL('image/' + format);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), 'qr_code.png');
      } else {
        const a = document.createElement('a');
        const my_evt = new MouseEvent('click');
        a.download = 'qr_code.png';
        a.href = dataURL;
        a.dispatchEvent(my_evt);
      }
    };
  }

  const handleDownload = async () => {
    const contentQR = await Api.getPromptpay(code);
    svgString2Image(contentQR, 740, 1050, 'png');
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Box p={2}>
          <h3>ดาวน์โหลด QR Code ด้านล่างเพื่อชำระเงิน</h3>
          <small>กรุณาชำระผ่าน QR Code นี้ภายใน 15 นาที</small>
          <img src={code} style={{ maxWidth: '100%' }}
          />
          <Button
            fullWidth
            color='inherit'
            variant='contained'
            onClick={handleDownload}
          >
            ดาวน์โหลด QR Code
          </Button>
          <Box mt={1}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={() => navigate(source, 'me.moronline://exit')}
            >
              ปิดหน้านี้
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PromptPay;
