import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { createSelectedMenusSelector, createInitialSelected } from '../../reducers/menus';
import { subtotal } from '../../helpers/utility';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    top: 0,
    zIndex: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  bar: {
    position: 'fixed',
    top: 0,
  },
  hidden: {
    visibility: 'hidden',
  },
  priceBadge: {
    top: '17px',
    right: '32px',
  },
  priceBadgeSpan: {
    borderRadius: 0,
  }
}));

const hiddenPages = ['/otp'];
const visiblePages = ['/channel/', '/menu'];
const visibleBackButton = ['/checkout'];
const titleMap = {
  '/pickLocation': 'กรุณาระบุที่อยู่'
}

export default function AppBarComponent() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const order = useSelector(createSelectedMenusSelector);
  const selected = useSelector(createInitialSelected);
  const session = useSelector(state => state.session);
  const hidden = hiddenPages.some(item => location.pathname.includes(item));

  const subtotalPrice = subtotal(order.items);
  const showCheckoutButton = (
    visiblePages.some(item => location.pathname.includes(item)) &&
    session.user && session.user.user_type === 'normal' &&
    order.items.length
  );
  const showBackButton = visibleBackButton.some(item => location.pathname.includes(item));

  const clickCheckout = () => {
    if (location.pathname.includes('/channel/')) {
      history.push('/menu')
    } else {
      history.push('/otp?noSkip=1&returnUri=/checkout')
    }
  }

  if (hidden) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            className={!showBackButton && classes.hidden}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={history.goBack}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {titleMap[location.pathname] || ''}
          </Typography>
          <IconButton
            className={!showCheckoutButton && classes.hidden}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={clickCheckout}
          >
            <Badge badgeContent={selected.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
            <Badge
              classes={{
                root: classes.priceBadge,
                badge: classes.priceBadgeSpan,
              }}
              badge={{borderRadius: 0}}
              badgeContent={`${subtotalPrice}.-`}
              color="error"
              invisible={subtotalPrice === 0}
            >
              <div />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
