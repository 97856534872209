import history from './history';
import { closeWindow } from '../apis/liff';

const MAP = {
  'me.moronline://consult': '/consult/patientApp/chooseCategory',
  'me.moronline://assessment/skipAi': '/assessment/personalInfo/?skipAi=1',
  'me.moronline://drugStore': 'https://drug.moronline.me/menu/?appToken={appToken}',
  'me.moronline://chatroom': '/consult/patientApp/channel/?channelId={param}',
  'me.moronline://order': '/drugStore/order/?id={param}',
  'me.moronline://laborder': '/labStore/order/?id={param}',
  'me.moronline://exit': '/',
};

export default (source, to) => {
  if (!to) {
    return;
  }

  if (!source) {
    source = 'browser';
  }

  if (source === 'android' || source === 'ios') {
    window.location.href = to;
    return;
  }

  if (source === 'liff' && to === 'me.moronline://exit') {
    closeWindow();
    return;
  }

  if (to.indexOf('http') === 0) {
    window.location.href = to;
    return;
  }

  const key = Object.keys(MAP).find(val => to.includes(val));
  let _to = MAP[key];

  if (!_to) {
    history.push(to);
    return;
  }

  if (localStorage) {
    _to = _to.replace('{appToken}', localStorage.getItem('token'));
  }
  if (_to.includes('{param}')) {
    const param = to.slice(to.lastIndexOf('/') + 1);
    _to = _to.replace('{param}', param);
  }

  if (_to.indexOf('http') === 0) {
    window.location.href = _to;
    return;
  }
  
  history.push(_to);
}