import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { displayCurrency } from '../../helpers/currency';
import GridList from '@material-ui/core/GridList';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PageLoader from '../PageLoader';
import Button from '../Button';
import ProductDialog from '../ProductDialog';

const useStyles = makeStyles(theme => ({
  menuImg: {
    width: '100%',
  },
  product: {
    background: '#fff6db',
    padding: 4,
  },
  root: {
    overflowX: 'hidden',
  },
  count: {
    display: 'flex',
    width: 35,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  outOfStock: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0
  }
}));

const Item = React.memo(({ imageUrl, title, price = 0, count = 0, onSelect, onDeselect, onPreview, isOutOfStock = false }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.product}>
        <img className={classes.menuImg} src={imageUrl} alt={title} onClick={onPreview}/>
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={7}>
            <span>{title}</span>
          </Grid>
          <Grid item>
            <span>{displayCurrency(price)}</span>
          </Grid>
        </Grid>
      </div>
      <Box my={1}>
        <Grid container>
          <Grid item xs={12}>
            {
              isOutOfStock ? (
                <Grid>
                  <p className={classes.outOfStock}>สินค้าหมด</p>
                </Grid>
              ) : (
                <Grid container justify="space-between" alignItems="center">
                  <div>
                    {count > 0 && <IconButton size="small" onClick={onDeselect}><RemoveIcon /></IconButton>}
                  </div>
                  {count > 0 && <Paper className={classes.count}>{count}</Paper>}
                  <Fab
                    size="small"
                    color="primary"
                    onClick={onSelect}
                  >
                    <AddIcon />
                  </Fab>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Box>
    </>
  )
})

export default React.memo(({ hasNext, selected, loading, menus, onLoadMore, addMenu, removeMenu }) => {
  const classes = useStyles();
  const [showProduct, setShowProduct] = React.useState();

  if (!menus.length && loading) return <PageLoader />;
  if (!menus.length) return 'ไม่พบรายการ';

  return (
    <>
      <ProductDialog
        productId={showProduct}
        open={!!showProduct}
        closeDialog={() => { setShowProduct(null) }}
      />
      <Grid container justify="center">
        <GridList spacing={8} cellHeight="auto" className={classes.root}>
          {
            menus.map((item, index) => {
              const count = selected.filter(s => s === item.id).length;
              return (
                <div key={index}>
                  <Item
                    imageUrl={item.image.medium}
                    title={item.name}
                    price={item.price}
                    count={count}
                    isOutOfStock={item.is_out_of_stock}
                    onPreview={() => setShowProduct(item.id)}
                    onSelect={() => addMenu(item.id)}
                    onDeselect={() => removeMenu(item.id)}
                  />
                </div>
              )
            })
          }
        </GridList>
        {
          hasNext && (
            <Grid item>
              <Box mt={2}>
                <Button  variant="outlined" color="primary" loading={loading} onClick={onLoadMore}>
                  ดูเพิ่ม
                </Button>
              </Box>
            </Grid>
          )
        }
      </Grid>
    </>
  )
})

