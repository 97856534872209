import { useSelector } from 'react-redux';
import { createMenuByIdSelector } from '../../reducers/menus';
import { makeStyles } from '@material-ui/core/styles';
import { displayCurrency } from '../../helpers/currency';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  dialogCustomizedWidth: {
    width: '75%',
    margin: '20px',
  },
  dialogContent: {
    padding: 0
  },
  textField: {
    margin: '0px 3%',
    padding: '10px 0px 10px 0px',
    width: '94%',
    fontSize: '0.95em'
  },
  icon: {
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'black',
    padding: '5px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    margin: '5px 10px',
  },
  price: {
    textAlign: 'end',
    margin: '5px 15px'
  },
  qualtity: {
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
  },
  content: {
    margin: '5px 10px',
    fontSize: '0.9em'
  },
  checkbox: {
    margin: '2px',
  },
  button: {
    width: '100%',
    backgroundColor: '#7c2427',
    color: 'white',
    fontWeight: 'bold',
    padding: '10px'
  },
  optional: {
    color: '#afafaf'
  },
  titleDivider: {
    height: '3px'
  },
  quantityAdjustContainer: {
    marginTop: '10px'
  },
  quantity: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '0px 15px',
    alignSelf: 'center'
  }
}));

const ProductDialog = ({ open, productId, closeDialog }) => {
  const classes = useStyles();
  const product = useSelector(state => createMenuByIdSelector(state, productId));

  if (!product) return null;

  const { name, price, image = {}, properties, indications, instruction, categories, is_out_of_stock } = product;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='xl'
      onClose={closeDialog}
      classes={{
        paperFullWidth: classes.dialogCustomizedWidth,
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
        <img alt={name} src={image.large} width="100%" />
        <List>
          {
            categories.length > 0 && (
              <ListItem>
                <ListItemText
                  primary={name}
                  secondary={categories[0].name}
                />
              </ListItem>
            )
          }
          <ListItem>
            <ListItemText
              primary={<>{displayCurrency(price)} <small>{is_out_of_stock ? 'Out of stock' : 'Available'}</small></>}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Detail"
              secondary={properties}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Indication"
              secondary={indications}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Dosage"
              secondary={instruction}
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}


export default ProductDialog;
