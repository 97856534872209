import './style.css';

export default () => {
  return (
    <div className="ticontainer">
      <div className="tiblock">
        <div className="tidot"></div>
        <div className="tidot"></div>
        <div className="tidot"></div>
      </div>
    </div>
  )
}
