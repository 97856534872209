import React from 'react';
import { IconButton, Button, TextField, Theme, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: '1 1 auto',
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
    '& :last-child': {
      flex: '0 1 auto',
    },
  },
  textField: {
    flex: '1 1 auto',
    minWidth: 0,
  },
}));

export default ({
  showOption, onSubmit, onSelect, disabled, onTyping
} : {
  showOption: boolean,
  disabled: boolean,
  onSubmit: (res : any) => void,
  onSelect: (res : any) => void,
  onTyping: () => void
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  const setResponse = React.useCallback((): void => {
    if (value) {
      const res = { type: 'text', value };
      onSubmit(res);
      setValue('');
    }
  }, [value]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (e.nativeEvent.isComposing) {
        return;
      }
      // if (e.key === 'Enter' && !e.shiftKey) {
      //   e.preventDefault();
      //   setResponse();
      // } else {
        onTyping();
      // }
    },
    [setResponse],
  );

  return (
    <div className={classes.container}>
      {
        showOption && (
          <IconButton onClick={onSelect}>
            <AddCircleOutlineIcon />
          </IconButton>
        )
      }
      <TextField
        className={classes.textField}
        style={{ flex: 1 }}
        placeholder="Type message"
        value={value}
        onChange={(e): void => setValue(e.target.value)}
        multiline
        inputProps={{ onKeyDown: handleKeyDown }}
        variant="outlined"
        rowsMax={10}
        disabled={disabled}
      />
      <Button
        type="button"
        onClick={setResponse}
        disabled={!value}
        variant="contained"
        color="primary"
        startIcon={<SendIcon />}
      >
        Send
      </Button>
    </div>
  );
}
