import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch(action.type) {
    case Constants.CHANNEL_DELETE_SUCCESS: {
      const newState = {...state};
      delete newState[action.payload.sid];
      return newState;
    }
    case Constants.CHANNELS_ALL_GET_SUCCESS: {
      return action.payload.items.reduce((prev, item) => {
        return {
          ...prev,
          [item.sid]: item,
        }
      }, state);
    }
    case Constants.CHANNELS_GET_SUCCESS: {
      return action.payload.items.reduce((prev, item) => {
        return {
          ...prev,
          [item.sid]: item,
        }
      }, state);
    }
    case Constants.CHANNEL_GET_SUCCESS:
    case Constants.CHANNEL_POST_SUCCESS: {
      return {
        ...state,
        [action.payload.sid]: action.payload
      }
    }
    default: return state;
  }
};

const allIds = (state = [], action) => {
  switch(action.type) {
    case Constants.CHANNEL_DELETE_SUCCESS: {
      return state.filter(s => s !== action.payload.sid);
    }
    case Constants.CHANNELS_ALL_GET_SUCCESS: {
      return action.payload.items.map(item => item.sid);
    }
    case Constants.CHANNELS_GET_SUCCESS: {
      return action.payload.items.map(item => item.sid);
    }
    case Constants.CHANNEL_GET_SUCCESS:
    case Constants.CHANNEL_POST_SUCCESS: {
      if (!state.includes(action.payload.sid)) {
        return [...state, action.payload.sid];
      }
      return state;
    }
    default: return state;
  }
};

export default combineReducers({
  byId,
  allIds,
});

export const createChannelsSelector = state => state.channels.allIds.map(id => state.channels.byId[id]);
export const createChannelByIdSelector = id => state => state.channels.byId[id]
