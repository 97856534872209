import { Box, Grow, Typography } from '@material-ui/core';
import { renderChatTime } from '../../helpers/date';
import Avatar from './Avatar';

const regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);

function replaceHyperlink(msg) {
  let urls = msg.match(regex);
  if (Array.isArray(urls)) {
    for (let url of urls) {
      msg = msg.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
    }
  }
  return msg;
}

export default ({
  message, date, self, author
}) => {

  if (message.type !== 'text') return null;
  
  const l = self ? '20%' : 0;
  const r = self ? 0 : '20%';
  const bgcolor = 'background.paper';
  const color = 'text.primary';
  const justifyContent = self ? 'flex-end' : 'flex-start';
  
  return (
    <Grow in>
      <Box
        flex="0 0 auto"
        my={1}
        pl={l}
        pr={r}
        display="flex"
        justifyContent={justifyContent}
      > 
        {
          !self && <Avatar user={author} />
        }
        <Box
          minWidth={0}
          py={1}
          px={2}
          bgcolor={bgcolor}
          color={color}
          borderRadius={16}
          boxShadow={2}
        >
          <small>{renderChatTime(date)}</small>
          <Typography
            variant="body1"
            style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          >
            <span dangerouslySetInnerHTML={{ __html: replaceHyperlink(message.value) }}/>
          </Typography>
        </Box>
      </Box>
    </Grow>
  )
}