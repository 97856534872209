import React from 'react';
import { createForm } from 'rc-form';
import { makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSymptomList } from '../../apis';
import { postSymptom } from '../../actions/symptoms';
import { createLoadingSelector } from '../../reducers/api';
import useTwilio from '../../hooks/useTwilio';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import SelectLocation from '../../components/SelectLocation';
import SkeletonLoader from '../../components/SkeletonLoader';
import { getUrlParams } from '../../helpers/location';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  }
}));

const CreateConversationPage = ({ form }) => {
  const [symptomList, setSymptomList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const Twilio = useTwilio();
  // const { line, user } = useSelector(state => state.session); // TODO: Line user may not available

  // const loadingChannels = useSelector(createLoadingSelector(['channels/get']));
  const posting = useSelector(createLoadingSelector(['symptom/post', 'channel/post']));

  const location = useLocation();
  const params = getUrlParams(location.search);
  const { skip } = params;

  React.useEffect(() => {
    fetch();
  }, [Twilio]);

  const fetch = async () => {
    if (!Twilio) return;

    setLoading(true);
    const channelList = await Twilio.getChannels();
    const chs = channelList.items.filter(item => item.attributes.room_type === 'pharmacist_room' && item.attributes.is_closed !== true)

    if (!skip) {
      if (chs.length !== 0) {
        history.replace(`/listChannel`);
        return;
      }
    }

    const res = await getSymptomList();
    setSymptomList(res);
    setLoading(false);
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (!Twilio) return;

    form.validateFields((err, values) => {
      if (!err) {
        dispatch(postSymptom(Number(values.symptom), values.period, values.detail))
        .then(payload => {
          return Twilio.getChannelByUniqueName(payload.chatroom_id);
        }).then(payload => {
          history.replace(`/channel/${payload.sid}`);
        });
      }
    })
  }

  if (loading) {
    return (
      <SkeletonLoader />
    )
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">
        เพื่อประโยชน์สูงสุดของท่าน กรุณากรอกข้อมูลให้เจ้าหน้าที่
      </Typography>
      <SelectLocation />
      <FormControl required>
        <InputLabel htmlFor="symptom">อาการเบื้องต้น</InputLabel>
        {form.getFieldDecorator("symptom", {
          rules: [{ required: true }],
        })(
          <Select
            native
            disabled={posting}
            inputProps={{
              name: 'symptom',
              id: 'symptom',
            }}
            error={form.getFieldError("symptom")}
          >
            <option value="" />
            {
              symptomList.map(item => {
                return (
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
              })
            }
          </Select>
        )}
      </FormControl>
      <FormControl required>
        <InputLabel htmlFor="period">มีอาการมานานเท่าใด</InputLabel>
        {form.getFieldDecorator("period", {
          rules: [{ required: true }],
        })(
          <Select
            native
            disabled={posting}
            inputProps={{
              name: 'period',
              id: 'period',
            }}
            error={form.getFieldError("period")}
          >
            <option value="" />
            <option value="1 วัน">1 วัน</option>
            <option value="2-3 วัน">2-3 วัน</option>
            <option value="7 วัน">7 วัน</option>
          </Select>
        )}
      </FormControl>
      {form.getFieldDecorator("detail", {
        rules: [{ required: true }],
      })(
        <TextField
          required
          multiline
          disabled={posting}
          label="ข้อมูลเพิ่มเติม"
          rows={4}
          variant="outlined"
          error={form.getFieldError("detail")}
        />
      )}
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={posting}>ส่งข้อมูล</Button>
    </div>
  )
}

export default createForm()(CreateConversationPage);
