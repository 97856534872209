import axios from 'axios';
import qs from 'qs';

const prefix = process.env.REACT_APP_API_URL_PREFIX || '';

class RequestHelper {

  authKey = 'token';
  accessToken;

  // constructor() {
    // if (typeof window !== 'undefined' && window.localStorage) {
    //   this.accessToken = window.localStorage.getItem('accessToken');
    // }
  // }

  request(method = 'get', url, data) {
    const headers = {
      // 'Content-Type': 'application/json',
    };
    if (this.accessToken) headers['Authorization'] = `${this.authKey} ${this.accessToken}`;
    // headers['Authorization'] = `${this.authKey} 5a4469998d1f0b7865fec62a09e87b27f342a02c`;
    // headers['Authorization'] = `${this.authKey} 208076c5e9fa34641e874d6a25307b671eb2dc61`;

    return axios({
      url: `${process.env.REACT_APP_API_URL}${url}`,
      method,
      data,
      headers,
    }).then(response => {
      return response.data.result || response.data.results || response.data;
    }).catch(error => {
      return Promise.reject(error.response.data && error.response.data.message);
    });
  }

  patch(url, payload) {
    return this.request('patch', url, payload);
  }

  post(url, payload) {
    return this.request('post', url, payload);
  }

  get(url) {
    return this.request('get', url);
  }

  delete(url) {
    return this.request('delete', url);
  }

  logIn(username, password) {
    return this._logIn('', { username, password })
  }

  logInFb(username, password) {
    return this._logIn('fb', { username, password })
  }

  logInLine(access_token, phone, otp_token, pin) {
    const payload = {
      access_token,
    };
    if (phone) payload.phone = phone;
    if (otp_token) payload.otp_token = otp_token;
    if (pin) payload.pin = pin;
    return this._logIn('line', qs.stringify(payload));
  }

  logInToken(token) {
    this.accessToken = token;
    return this.me();
  }

  _logIn(method = '', payload) {
    return this.post(`auth/${!!method ? `${method}/` : method}login/`, payload)
      .then(response => {
        this.accessToken = response.key;
        // if (typeof window !== 'undefined' && window.localStorage) {
        //   window.localStorage.setItem('accessToken', response.key);
        // }
        return this.me();
      })
  }

  logOut() {
    return this.post('auth/logout/')
      .then(response => {
        this.accessToken = undefined;
        // if (typeof window !== 'undefined' && window.localStorage) {
        //   window.localStorage.removeItem('accessToken');
        // }
        return response;
      })
  }

  logInTwilio() {
    return this.post(`auth/${prefix}twilio_authentication/`)
  }

  me() {
    return this.get('auth/me/');
  }

  updateMe(first_name, last_name, email) {
    return this.patch('auth/me/', { first_name, last_name, email });
  }

  mockPharmacist() {
    this.accessToken = "e0f4778cf3ccf4b8fb61b8957e1a4d5c4aa28a29";
    return this.me();
  }
}

export default new RequestHelper();
