export enum QuestionnaireItemInputType {
  TEXT = 'text',
  NUMBER = 'number',
  ONE_CHOICE = 'one_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
};

export enum GenderType {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
};

export enum UserType {
  PHARMACIST = 'pharmacist',
  DOCTOR = 'doctor',
  NURSE = 'nurse',
  PSYCHOLOGIST = 'psychologist',
  OCCUPATIONAL_THERAPIST = 'occupationaltherapist',
  PHYSICAL_THERAPIST = 'physicaltherapist',
  OTHER = 'otherpersonnel',
};

export enum BloodType {
  A = 'A',
  B = 'B',
  AB = 'AB',
  O = 'O'
};

export enum RelationType {
  SELF = 'self',
  PARENT = 'parent',
  CHILD = 'child',
  OTHER = 'other',
};

export enum ProductType {
  PRODUCT = 1,
  PACKAGE = 2,
};

export enum ChatMessageType {
  VIDEO_CALL = 'videocall',
  TEXT = 'text',
  CONSENT = 'consent',
  FILE = 'file',
  CATALOG = 'catalog',
  MEDIA = 'media',
  MEDICAL_RECORD = 'medicalrecord',
  ASSESSMENT = 'assessment',
};

export enum PaymentType {
  MOBILE_BANK_OMISE = 'mobile_banking_omise',
  MOBILE_BANK_BBL = 'mobile_banking_bbl',
  MOBILE_BANK_KBANK = 'mobile_banking_kbank',
  MOBILE_BANK_KTB = 'mobile_banking_ktb',
  MOBILE_BANK_BAY = 'mobile_banking_bay',
  MOBILE_BANK_SCB = 'mobile_banking_scb',
  PROMPTPAY = 'promptpay',
  DEFAULT_CREDIT_CARD = 'default_credit_card',
  CREDIT_CARD = 'credit_card',
  CASH = 'cash',
}

export enum PaymentStatus {
  WAITING = 'waiting_paid',
  PAID = 'paid',
}

export enum AssessmentType {
  AI = 'ai',
  Questionnaire = 'questionnaire',
}

export const ASSESSMENT_MENU = [
  {
    title: 'ประเมินโรคทั่วไป',
    image: '/img/menu/med.png',
    url: '/assessment/personalInfo/'
  },
  {
    title: 'ประเมินสุขภาพจิต',
    image: '/img/menu/mental.png',
    url: '/menu/mental'
  },
  {
    title: 'ประเมินพัฒนาการเด็ก',
    image: '/img/menu/child.png',
    url: '/menu/child'
  },
]

export const PAYMENTS = [{
  type: PaymentType.MOBILE_BANK_BBL,
  img: 'bbl-m.png',
  title: 'M Banking ธนาคารกรุงเทพ',
  uriPattern: 'bualuangmbanking://'
}, {
  type: PaymentType.MOBILE_BANK_KBANK,
  img: 'kplus.png',
  title: 'M Banking ธนาคารกสิกรไทย',
  uriPattern: 'kpaymentgateway-services'
// }, {
//   type: PaymentType.MOBILE_BANK_KTB,
//   img: 'promptpay.png',
//   title: 'Mobile Banking ธนาคารกรุงไทย',
//   uriPattern: 'omise.co/payments',
}, {
  type: PaymentType.MOBILE_BANK_BAY,
  img: 'kma.png',
  title: 'M Banking ธนาคารกรุงศรี',
  uriPattern: 'krungsri-kma://',
}, {
  type: PaymentType.MOBILE_BANK_SCB,
  img: 'scb-easy.png',
  title: 'M Banking ธนาคารไทยพาณิชย์',
  uriPattern: 'scbeasy://',
}, {
  type: PaymentType.PROMPTPAY,
  img: 'promptpay.png',
  title: 'พร้อมเพย์',
}, {
  type: PaymentType.MOBILE_BANK_OMISE,
  img: 'omise.svg',
  title: 'Omise',
  uriPattern: 'omise.co/payments',
}, {
  type: PaymentType.CASH,
  img: 'cash.png',
  title: 'เก็บเงินปลายทาง',
}];
