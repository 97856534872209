import React from 'react';
import { getOfficeHour } from '../apis/index';

// Hook
export default function useOfficeHour() {
  const [officeHour, setOfficeHour] = React.useState(true);

  React.useEffect(() => {
    getOfficeHour().then((res) => {
      setOfficeHour(res.is_open);
    })
  }, []);

  return officeHour;
}