import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { displayCurrency } from '../../helpers/currency';
import { setMenuQuantity } from '../../actions/menus';
import { createMenuByIdSelector, createInitialSelected } from '../../reducers/menus';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
// import Input from '@material-ui/core/Input';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  dialogCustomizedWidth: {
    width: '75%',
    margin: '20px',
  },
  dialogContent: {
    padding: 0
  },
  textField: {
    margin: '0px 3%',
    padding: '10px 0px 10px 0px',
    width: '94%',
    fontSize: '0.95em'
  },
  icon: {
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'black',
    padding: '5px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    margin: '5px 10px',
  },
  price: {
    textAlign: 'end',
    margin: '5px 15px'
  },
  qualtity: {
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
  },
  content: {
    margin: '5px 10px',
    fontSize: '0.9em'
  },
  checkbox: {
    margin: '2px',
  },
  button: {
    width: '100%',
    backgroundColor: '#7c2427',
    color: 'white',
    fontWeight: 'bold',
    padding: '10px'
  },
  optional: {
    color: '#afafaf'
  },
  titleDivider: {
    height: '3px'
  },
  quantityAdjustContainer: {
    marginTop: '10px'
  },
  quantity: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '0px 15px',
    alignSelf: 'center'
  }
}));

const ProductDialog = ({ open, productId, onClose, update }) => {
  const classes = useStyles();
  // const noteTextBox = useRef(null);
  const dispatch = useDispatch();
  const product = useSelector(state => createMenuByIdSelector(state, productId));
  const selectedMenus = useSelector(createInitialSelected);
  const countQuantity = selectedMenus.filter(menuId => productId === menuId).length + 1;
  // const oldNote = (selectedMenusDetail[id] || {}).note || '';
  const [quantity, setQuantity] = useState(countQuantity);

  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setQuantity(countQuantity);
  }, [countQuantity, open]);

  useEffect(() => {
    const screenWidth = window.innerWidth - 48;
    const screenHeight = screenWidth / 1.8;
    setScreenHeight(screenHeight);
  }, []);

  const decreaseQuantity = () => {
    setQuantity(Math.max(quantity - 1, 0));
  }

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  }

  const updateBasket = () => {
    dispatch(setMenuQuantity(productId, quantity));
    onClose();
  }

  // const handleNoteChange = (event) => {
  //   setNote(event.target.value);
  // }

  // function scrollToTextBox() {
  //   setTimeout(() => {
  //     noteTextBox.current.scrollIntoView(false);
  //   }, 500)
  // }
  if (!product) return null;

  const { name, price, image = {}, properties/**, indications, instruction, categories, is_out_of_stock */ } = product;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='xl'
      onClose={onClose}
      classes={{
        paperFullWidth: classes.dialogCustomizedWidth,
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <img className={classes.image} style={{ height: screenHeight }} src={image.large} />
        <Grid container direction="row" justify="space-between">
          <Grid item xs={9}>
            <h3 className={classes.title}>{name}</h3>
          </Grid>
          <Grid item xs={3}>
            <h3 className={classes.price}>{displayCurrency(price)}</h3>
          </Grid>
        </Grid>
        <Divider className={classes.titleDivider} light={true} />
        <p className={classes.title}>{properties}</p>
        <Divider />
        <Grid container direction="row" justify="center" className={classes.quantityAdjustContainer}>
          <div>
            <IconButton color="primary" onClick={decreaseQuantity}>
              <RemoveCircleOutlineIcon fontSize="large" />
            </IconButton>
          </div>
          <p className={classes.quantity}>{quantity}</p>
          <div>
            <IconButton color="primary" onClick={increaseQuantity}>
              <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingTop: '5px' }}>
        <Button fullWidth color="primary" variant="contained" onClick={updateBasket} >
          {update ? 'อัพเดท' : 'ใส่ตะกร้า'} {`${displayCurrency(quantity * price)}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default ProductDialog;
