import axios from 'axios';

import RequestHelper from './requestHelper';

const prefix = process.env.REACT_APP_API_URL_PREFIX || '';

const encode = object => new URLSearchParams(Object.keys(object).reduce((prev, key) => !object[key] ? prev : { ...prev, [key]: object[key] }, {})).toString()

export const logInLine = (access_token, phone, otpToken, pin) => RequestHelper.logInLine(access_token, phone, otpToken, pin);

export const logIn = (username, password) => RequestHelper.logIn(username, password);

export const logInToken = (token) => RequestHelper.logInToken(token);

export const logOut = () => RequestHelper.logOut();

export const logInTwilio = () => RequestHelper.logInTwilio();

export const mockPharmacist = () => RequestHelper.mockPharmacist();

export const become = () => RequestHelper.me();

export const updateMe = (first_name, last_name, email) => RequestHelper.updateMe(first_name, last_name, email);

export const checkConsentNews = () => RequestHelper.get(`consents/fornews/check/`);

export const postConsentNews = (payload) => RequestHelper.post(`consents/fornews/accept/`, payload);

export const getMenusWithLimit = (limit) => (search, categoryId, page, lat, lng, collaboration, filter) => 
  RequestHelper.get(
    `${prefix}product/?${encode({
      limit,
      search,
      offset: page ? (page - 1) * limit : 0,
      categories__id: categoryId ? categoryId : undefined,
      lat,
      lng,
      collaboration,
      ...filter
    })}`
  );

export const getMenus = getMenusWithLimit(12);

export const getMenu = ids => {
  let res = ids;
  if (Array.isArray(ids)) {
    res = ids.join(',');
  }
  return RequestHelper.get(`${prefix}product/?id=${res}`);
}

export const getCategories = () => RequestHelper.get(`${prefix}product/category/`);

// export const postOrder = payload => RequestHelper.post(`${prefix}order/`, payload);

export const postOrder = (payload, membership = false, option = 'user') => {
  if (membership) {
    return RequestHelper.post(`membership/pharmacy/order/?flow=${option}`, payload);
  } else {
    return RequestHelper.post(`${prefix}order/`, payload);
  }
}

// export const postOrderSummary = payload => RequestHelper.post(`order/summary/`, payload);

// export const postOrderMembershipSummary = payload => RequestHelper.post(`/membership/pharmacy/order/summary/`, payload);

export const postOrderSummary = (payload, membership = false, option = 'user') => {
  if (membership) {
    return RequestHelper.post(`membership/pharmacy/order/summary/?flow=${option}`, payload);
  } else {
    return RequestHelper.post(`order/summary/`, payload);
  }
}

export const getOrder = id => RequestHelper.get(`${prefix}order/${id}/`);

export const getOrders = () => RequestHelper.get(`${prefix}order/?limit=10`);

export const getPharmacist = id => RequestHelper.get(`${prefix}pharmacist/${id}/`);

export const getPharmacists = () => RequestHelper.get(`${prefix}pharmacist/?limit=10`);

export const getAddresses = () => RequestHelper.get('address/');

export const postAddress = (id, payload) => {
  if (id) {
    return RequestHelper.patch(`address/${id}/`, payload);
  } else {
    return RequestHelper.post('address/', payload);
  }
}

export const postRequestOTP = phone => RequestHelper.post('auth/request_otp/', { phone });

export const postVerifyOTP = (pin, phone, otp_token) => RequestHelper.post('auth/verify_otp/', { pin, phone, otp_token });

export const postSymptom = (payload) => RequestHelper.post(`${prefix}symptoms/customer/`, payload)

export const getSymptomList = () => RequestHelper.get(`${prefix}symptoms/`);

export const getSymptoms = (isPharmacist) => RequestHelper.get(`${prefix}symptoms/${isPharmacist ? 'pharmacist' : 'customer'}/`);

export const getSymptom = (isPharmacist, id) => RequestHelper.get(`${prefix}symptoms/${isPharmacist ? 'pharmacist' : 'customer'}/${id}/`);

export const pharmacistCloseCase = (id) => RequestHelper.post(`${prefix}symptoms/pharmacist/${id}/close/`);

export const pharmacistAssign = (id) => RequestHelper.post(`${prefix}symptoms/pharmacist/empty/${id}/assignme/`);

export const postCreditCard = token => RequestHelper.post('customer/cards/', { token });

export const getCreditCards = () => RequestHelper.get('customer/cards/');

export const patchCreditCard = (id, payload) => RequestHelper.patch(`customer/cards/${id}/`, payload);

export const removeCreditCard = (id) => RequestHelper.delete(`customer/cards/${id}/`);

export const getShippingPrice = (latitude, longitude, price) => RequestHelper.post(`${prefix}order/calculate/deliveryprice/`, { latitude, longitude, price })

export const getPriceList = () => RequestHelper.get(`${prefix}order/deliveryprices/`)

export const getFixedPriceList = () => RequestHelper.get(`${prefix}order/fixeddeliveryprices/`)

export const getOfficeHour = () => RequestHelper.get(`${prefix}office/officehour/`)

export const getCollaboration = (latitude, longitude, tenant) =>
  RequestHelper.get(
    `${prefix}collaboration/nearest/?${encode({
      latitude,
      longitude,
      tenant
    })}`
  )
  
export const getPromptpay = (url) => RequestHelper.get(`promptpay/download/?scannable_url=${url}/`)

export const getListPromotionAppointment = () => RequestHelper.get(`promotions/collect/customer/`);

export const postSearchandCollectPromotionAppointment = payload => RequestHelper.post(`promotions/searchandcollect/`, payload);

export const getLineToken = payload =>
  axios.post('https://api.line.me/oauth2/v2.1/token', payload, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });