import * as Constants from '../actions/constants';
import { deliveryTomorrow } from '../helpers/date';
import { PaymentType } from '../helpers/constants';

const initialState = {
  address: null,
  payment: null,
  name: '',
  phone: '',
  remark: '',
  deliveryDate: deliveryTomorrow(),
  deliveryPrice: 0,
  deliveryMethod: 'delivery', // delivery, post_shipment
  distance: 0,
  priceList: null, // Array
  fixedPriceList: null, // Object
  collaboration: null, // Object
  tenantId: null, // id
  promotionId: null,
}

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_SHIPPING_FIXED_PRICE_LIST:
      return {
        ...state,
        fixedPriceList: action.payload,
      }
    case Constants.SET_SHIPPING_PRICE_LIST:
      return {
        ...state,
        priceList: action.payload,
      }
    case Constants.SET_SHIPPING_PRICE: {
      const res = {
        ...state,
        deliveryPrice: action.payload.delivery_price,
        distance: action.payload.distance,
      };
      if (state.deliveryMethod === 'delivery') {
        res.deliveryPrice = action.payload.delivery_price;
      } else if (state.deliveryMethod === 'post_shipment') {
        res.deliveryPrice = action.payload.fixed_delivery_price;
      }
      return res;
    }
    case Constants.SET_SHIPPING_INFO:
      return {
        ...state,
        ...action.payload
      }
    case Constants.REMOVE_PROMOTION: {
      if (state.promotionId === action.payload) {
        return {
          ...state,
          promotionId: null,
        }
      }
      return state;
    }
    case Constants.SET_PROMOTION:
      return {
        ...state,
        promotionId: action.payload,
      }
    case Constants.SET_TENANT:
      return {
        ...state,
        tenantId: action.payload,
      }
    case Constants.SET_CASH:
      return {
        ...state,
        payment: PaymentType.CASH,
      }
    case Constants.SET_PROMPTPAY:
      return {
        ...state,
        payment: PaymentType.PROMPTPAY,
      }
    case Constants.SET_PAYMENT_TYPE:
      return {
        ...state,
        payment: action.payload,
      }
    case Constants.CREDIT_CARDS_GET_SUCCESS:
      return {
        ...state,
        payment: action.payload.find(res => !!res.is_default)
      }
    case Constants.CREDIT_CARD_PATCH_SUCCESS:
      return {
        ...state,
        payment: action.payload,
      };
    case Constants.UPDATE_ME_SUCCESS:
    case Constants.LOGIN_LINE_SUCCESS:
    case Constants.BECOME_USER:
      if (!action.payload) return state;
      return {
        ...state,
        name: action.payload.first_name && `${action.payload.first_name} ${action.payload.last_name}`,
        phone: action.payload.phone,
        deliveryMethod: action.payload.is_member ? 'post_shipment': 'delivery',
      };
    case Constants.ADDRESSES_GET_SUCCESS:
      return {
        ...state,
        address: action.payload[0],
      };
    case Constants.ADDRESS_POST_SUCCESS:
      return {
        ...state,
        address: action.payload,
      };
    case Constants.COLLABORATION_GET_SUCCESS: {
      const res = {
        ...state,
        collaboration: action.payload
      };
      if (!res.collaboration.within_the_range) {
        res.deliveryMethod = 'post_shipment';
      }
      return res;
    }
    case Constants.ORDER_POST_SUCCESS:
      return {
        ...state,
        taxInvoice: false,
      };
    default:
      return state;
  }
}

export const createPriceByDistance = state => {
  const { deliveryMethod } = state.shippingInfo;
  return createPriceByDeliveryType(state)[deliveryMethod];
}

export const createPriceByDeliveryType = state => {
  const { fixedPriceList, priceList, distance } = state.shippingInfo;
  return {
    'delivery': (priceList || []).map(parseAllFloat).find(item => 
      distance < parseFloat(item['end_km']) && distance >= parseFloat(item['start_km'])
    ) || {},
    'post_shipment': fixedPriceList || {}
  }
}

const parseAllFloat = (item) => {
  return {
    delivery_price: parseFloat(item["delivery_price"]) || 0,
    end_km: parseFloat(item["end_km"]) || 0,
    minimum_price: parseFloat(item["minimum_price"]) || 0,
    start_km: parseFloat(item["start_km"]) || 0,
  }
}

export const createTenantSelector = state => state.tenantId;