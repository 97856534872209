import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch(action.type) {
    case Constants.PHARMACIST_GET_SUCCESS: {
      return {
        ...state,
        [action.payload.unique_id]: action.payload,
      };
    }
    case Constants.PHARMACISTS_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.unique_id]: item,
        }
      }, state);
    }
    default: return state;
  }
}

export default combineReducers({
  byId,
});

export const createAllPharmacistSelector = state => Object.keys(state.pharmacists.byId).map(id => state.pharmacists.byId[id]);
export const createPharmacistSelector = (state, id) => state.pharmacists.byId[id];

