import * as Constant from './constants';
import * as Api from '../apis';
import { createSelectedMenusSelector } from '../reducers/menus';
import { updateMe } from './session';
import { PaymentType } from '../helpers/constants';

export const postOrder = (membership) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ORDER_POST_REQUEST });
    const order = createSelectedMenusSelector(getState());

    const { shippingInfo, session } = getState();
    const { payment, name, phone, deliveryMethod, remark, deliveryDate, collaboration, promotionId } = shippingInfo;

    const _name = name ? name.split(' ') : [];
    const firstName = _name[0];
    const lastName = _name[1];

    const input = {
      address: {
        ...shippingInfo.address,
        is_save: true,//!session.user || !(shippingInfo.address && shippingInfo.address.id),
        customer_name: name,
        phone: phone,
      },
      items: order.items.map(item => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      order_type: deliveryMethod,
      delivery_date: deliveryDate,
      remark: remark,
      collaboration: collaboration.id,
      promotion_id: promotionId,
    };

    if (payment && typeof payment === 'object') {
      input.checkout_method = {
        method: PaymentType.DEFAULT_CREDIT_CARD
      };
    } else {
      input.checkout_method = {
        method: payment
      };
    }

    return Promise.resolve().then(() => {
      if (!firstName && !lastName) return Promise.reject('กรุณากรอกชื่อ');
      if (!phone) return Promise.reject('กรุณากรอกเบอร์โทร');
      if (!shippingInfo.address) return Promise.reject('กรุณาระบุที่อยู่');
      if (!payment) return Promise.reject('กรุณาเลือกช่องทางการชำระเงิน');
      if (!session.user && !session.line && !session.facebook) {
        return Promise.reject('กรุณาเข้าสู่ระบบ');
      }
      return !session.user.first_name ? dispatch(updateMe(firstName, lastName)) : Promise.resolve()
    }).then(() => {
      return Api.postOrder(input, membership);
    }).then(payload => {
      dispatch({ type: Constant.ORDER_POST_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.ORDER_POST_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const postOrderSummary = (membership) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ORDER_SUMMARY_POST_REQUEST });
    const order = createSelectedMenusSelector(getState());

    const { shippingInfo } = getState();
    const { payment, name, phone, deliveryMethod, deliveryDate, remark, collaboration, promotionId } = shippingInfo;
    const input = {
      address: {
        ...shippingInfo.address,
        is_save: true,
        customer_name: name,
        phone: phone,
      },
      items: order.items.map(item => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      order_type: deliveryMethod,
      delivery_date: deliveryDate,
      remark: remark,
      collaboration: collaboration.id,
      promotion_id: promotionId,
    };

    if (payment && typeof payment === 'object') {
      input.checkout_method = {
        method: 'default_credit_card'
      }
    }

    return Api.postOrderSummary(input, membership)
      .then(payload => {
        dispatch({ type: Constant.ORDER_SUMMARY_POST_SUCCESS, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.ORDER_SUMMARY_POST_FAILED, message });
        return Promise.reject(message);
      })
  }
}

export const getOrder = id => {
  return dispatch => {
    dispatch({ type: Constant.ORDER_GET_REQUEST, id });
    return Api.getOrder(id).then(payload => {
      dispatch({ type: Constant.ORDER_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.ORDER_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getOrders = () => {
  return dispatch => {
    dispatch({ type: Constant.ORDERS_GET_REQUEST });
    return Api.getOrders().then(payload => {
      dispatch({ type: Constant.ORDERS_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.ORDERS_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}
