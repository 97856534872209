import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCash, setPaymentType, patchCreditCard, getCreditCards } from '../../actions/creditCards';
import { createAllCreditCardSelector } from '../../reducers/creditCards';
import { makeStyles } from '@material-ui/core/styles';
import { PaymentType, PAYMENTS } from '../../helpers/constants';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import PaymentListSlideDialog from '../PaymentListSlideDialog';
import Table from '../Table';
import Cell from '../Cell';

const useStyles = makeStyles(theme => ({
  image: {
    position: 'relative',
    top: 2,
    height: 16
  },
  editIcon: {
    top: 4,
    position: 'relative',
  }
}));

export default ({ readOnly }) => {
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const creditCards = useSelector(createAllCreditCardSelector)
  const shippingInfo = useSelector(state => state.shippingInfo);
  const currentPayment = shippingInfo.payment;

  React.useEffect(() => {
    dispatch(getCreditCards());
  }, []);

  const handleClickOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const icon = (() => {
    let src;
    if (!currentPayment) return null;

    if (typeof currentPayment === "object") {
      if (currentPayment.brand) src = `${currentPayment.brand.toLowerCase()}.png`;
    } else {
      const p = PAYMENTS.find(val => val.type === currentPayment);
      if (p) {
        src = p.img;
      }
      if (currentPayment === PaymentType.CREDIT_CARD) src = 'visa.png';
    }

    return <img src={require(`../../assets/creditcards/${src}`).default} className={classes.image} />
  })();


  const label = (() => {
    if (!currentPayment) return 'เลือก';
    if (typeof currentPayment === "object") {
      return `บัตร ${currentPayment.brand} ${currentPayment.last_digits}`;
    } else {
      const p = PAYMENTS.find(val => val.type === currentPayment);
      if (p) {
        return p.title;
      }
      if (currentPayment === PaymentType.CREDIT_CARD) return 'บัตร Credit/Debit';
    }
  })();

  const onClickPayment = async (type) => {
    Promise.resolve().then(() => {
      if (type === PaymentType.CASH) {
        return dispatch(setCash());
      } else if ([
        PaymentType.PROMPTPAY,
        PaymentType.MOBILE_BANK_BBL,
        PaymentType.MOBILE_BANK_KBANK,
        PaymentType.MOBILE_BANK_KTB,
        PaymentType.MOBILE_BANK_BAY,
        PaymentType.MOBILE_BANK_SCB,
      ].includes(type)) {
        return dispatch(setPaymentType(type));
      } else if (currentPayment && type === currentPayment.id) {
        return Promise.resolve();
      } else {
        return dispatch(patchCreditCard(type));
      }
    }).finally(() => {
      handleClose();
    })
  };

  return (
    <Table>
      {
        !readOnly && (
          <PaymentListSlideDialog
            open={modal}
            onClose={handleClose}
            title="ช่องทางการชำระเงิน"
            creditCards={creditCards}
            currentPayment={currentPayment}
            onSelectPayment={onClickPayment}
          />
        )
      }
      <TableBody>
        <TableRow>
          <Cell>ช่องทางการชำระเงิน</Cell>
          <Cell align="right" onClick={handleClickOpen}>
            { (!readOnly && currentPayment) && <span style={{color: 'red'}}>เปลี่ยน &nbsp;&nbsp;</span> }
            {
              readOnly ? (
                <>{icon} {label}</>
              ) : (
                <Link color="secondary">
                  {icon} {label}
                </Link>
              )
            }
          </Cell>
        </TableRow>
      </TableBody>
    </Table>
  )
};
