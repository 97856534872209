import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link as GLink } from 'react-router-dom';
import { getAddresses } from '../../actions/shippingInfo';
import Link from '@material-ui/core/Link';

export default memo(({ force = false, disabled = false, address }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shippingInfo = useSelector(state => state.shippingInfo);
  const session = useSelector(state => state.session);
  const _address = address || shippingInfo.address;

  useEffect(() => {
    fetch();
  }, [])

  const fetch = async () => {
    const res = await dispatch(getAddresses());
    if (force && session.user && session.user.user_type === 'normal') {
      if (!res[0]) {
        alert('กรุณาระบุที่อยู่ก่อนใช้บริการ');
        history.push('/pickLocation');
      }
    }
  }

  if (disabled) return _address ? _address.address : 'ระบุที่อยู่';
  return (
    <GLink to="/pickLocation">
      <Link color="secondary">
        {
          _address ? `${_address.address.slice(0,50)}...` : 'ระบุที่อยู่'
        }
      </Link>
    </GLink>
  );
});
