import * as Constant from './constants'
import * as Twilio from '../apis/twilio'

export const getChannel = (channelId) => {
  return dispatch => {
    dispatch({ type: Constant.CHANNEL_GET_REQUEST });
    return Twilio.getChannel(channelId).then(payload => {
      dispatch({ type: Constant.CHANNEL_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CHANNEL_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getChannels = () => {
  return dispatch => {
    dispatch({ type: Constant.CHANNELS_GET_REQUEST });
    return Twilio.getChannels().then(payload => {
      dispatch({ type: Constant.CHANNELS_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CHANNELS_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const createChannel = (uniqueName, friendlyName) => {
  return dispatch => {
    dispatch({ type: Constant.CHANNEL_POST_REQUEST });
    return Twilio.createChannel(uniqueName, friendlyName).then((payload) => {
      dispatch({ type: Constant.CHANNEL_POST_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CHANNEL_POST_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getAllChannels = () => {
  return dispatch => {
    dispatch({ type: Constant.CHANNELS_ALL_GET_REQUEST });
    return Twilio.getPublicChannelDescriptors().then(payload => {
      dispatch({ type: Constant.CHANNELS_ALL_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CHANNELS_ALL_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const deleteChannel = (channel) => {
  return dispatch => {
    dispatch({ type: Constant.CHANNEL_DELETE_REQUEST });
    return Twilio.deleteChannel(channel).then(payload => {
      dispatch({ type: Constant.CHANNEL_DELETE_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CHANNEL_DELETE_FAILED, message });
      return Promise.reject(message);
    })
  }
}
