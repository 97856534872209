import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { createPriceByDeliveryType } from '../../reducers/shippingInfo';
import { displayCurrency } from '../../helpers/currency';
import useCollaboration from '../../hooks/useCollaboration';
import useOfficeHour from '../../hooks/useOfficeHour';
import InfoDialog from '../../components/InfoDialog';

const useStyles = makeStyles(theme => ({
  promotionPopup: {
    textAlign: 'center',
    '& h1': {
      color: '#f20000',
    },
    '& p': {
      color: theme.palette.primary.main,
    },
    '& h3': {
      margin: 0
    },
    '& h2': {
      backgroundColor: theme.palette.primary.main,
      display: 'inline-block',
      padding: theme.spacing(0.2, 4),
      borderRadius: 8,
      color: 'white',
    }
  }
}));

// Define as global variable
let popupShown = false;

const PromotionDialog = ({ startOpen = false }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(startOpen);
  const [showAlert, setShowAlert] = React.useState(false);
  const priceByDeliveryType = useSelector(createPriceByDeliveryType);
  const shippingInfo = useSelector(state => state.shippingInfo);
  const collaboration = useCollaboration(shippingInfo.address);
  const officeHour = useOfficeHour();

  const handleOnClose = () => {
    setShowAlert(false);
    setOpen(false);
  }

  React.useEffect(() => {
    setOpen(startOpen);

    if (officeHour && collaboration && !popupShown) {
      if (collaboration?.tenant?.id === 1) { // Hard code check for drugsquare popup
        popupShown = true;
        setShowAlert(true);
      }
    }
  }, [officeHour, collaboration, startOpen]);


  let alertBox1;
  let alertBox2 = (
    <>
      <h1>โปรส่งปกติ</h1>
      <p>ภายใน 1 - 2 วัน</p>
      <p>เมื่อซื้อสินค้าครบ</p>
      <h3>{displayCurrency(priceByDeliveryType['post_shipment']['minimum_price'], 0, 'บาท')}</h3>
      <h2 onClick={() => setShowAlert(false)}>ส่งฟรี!!</h2>
    </>
  );
  if (collaboration) {
    if (collaboration.within_the_range) {
      alertBox1 = (
        <>
          <h1>โปรส่งด่วน</h1>
          <p>ภายใน 4 ชม.</p>
          <p>ระยะทางไม่เกิน 6 กิโลเมตร</p>
          <p>เมื่อซื้อสินค้าครบ</p>
          <h3>{displayCurrency(priceByDeliveryType['delivery']['minimum_price'], 0, 'บาท')}</h3>
          <h2 onClick={() => setShowAlert(false)}>ส่งฟรี!!</h2>
        </>
      );
      if (!officeHour) {
        alertBox1 = (
          <>
            <h1>โปรส่งด่วน</h1>
            <p>เนื่องจากอยู่นอกเวลาทำการ เราจะเริ่มส่งสินค้าให้ในวันถัดไป ภายใน 12.00 น.</p>
            <p>เมื่อซื้อสินค้าครบ</p>
            <h3>{displayCurrency(priceByDeliveryType['delivery']['minimum_price'], 0, 'บาท')}</h3>
            <h2 onClick={() => setShowAlert(false)}>ส่งฟรี!!</h2>
          </>
        );
      }
    } else {
      alertBox1 = null;
    }
  }

  return (
    <InfoDialog
      open={showAlert && open}
      onConfirm={() => handleOnClose(false)}
      onClose={() => handleOnClose(false)}
      content={
        <div className={classes.promotionPopup}>
          {alertBox1}
          {alertBox2}
        </div>
      }
    />
  );
}

export default PromotionDialog;
