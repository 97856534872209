import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch(action.type) {
    case Constants.ORDER_GET_SUCCESS:
    case Constants.ORDER_POST_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }
    case Constants.ORDERS_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state);
    }
    default: return state;
  }
}

export default combineReducers({
  byId,
});

export const createAllOrderSelector = state => Object.keys(state.orders.byId).map(id => state.orders.byId[id]);
export const createOrderSelector = (state, id) => state.orders.byId[id];

