import { useState } from 'react';
import { Box, Grow, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMenuQuantity, getMenu, clearMenu } from '../../actions/menus';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CustomAvatar from './Avatar';
import ConfirmDialog from '../ConfirmDialog';

// interface Product {
//   id: string
//   imageUrl: string
//   title: string
//   price: string
//   quantity: number
// }

export default ({
  message, self, showBuy, author
}) => {
  const [modal, setModal] = useState(false);
  const [cartAdded, setCartAdded] = useState(false);
  const dispatch = useDispatch();

  const onCheckout = async () => {
    setModal(false);
    dispatch(clearMenu());
    for (var i = 0; i < message.value.length; i++) {
      const product = message.value[i];
      await dispatch(getMenu(product.id));
      dispatch(setMenuQuantity(product.id, product.quantity));
    }
    setCartAdded(true);
  }

  if (message.type !== 'catalog') return null;

  const l = self ? '20%' : 0;
  const r = self ? 0 : '20%';
  const bgcolor = 'background.paper';
  const color = 'text.primary';
  const justifyContent = self ? 'flex-end' : 'flex-start';

  return (
    <>

      <ConfirmDialog
        open={modal}
        onConfirm={onCheckout}
        onClose={() => setModal(false)}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        content="หากท่านมีสินค้าอยู่ในตะกร้าแล้ว ข้อมูลจะถูกลบ"
        title="ใส่สินค้าลงตะกร้า"
      />

      <Grow in>
        <Box
          flex="0 0 auto"
          my={1}
          pl={l}
          pr={r}
          display="flex"
          justifyContent={justifyContent}
        >
          {
            !self && <CustomAvatar user={author} />
          }
          <Box
            minWidth={250}
            py={1}
            px={2}
            bgcolor={bgcolor}
            color={color}
            borderRadius={16}
            boxShadow={2}
          >
            <List style={{ minWidth: 250 }}>
              {
                message.value.map((item, index) => {
                  return (
                    <Link to={`/product/${item.id}`} style={{ width: '100%' }} key={index}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src={item.imageUrl} variant="rounded" />
                        </ListItemAvatar>
                        <ListItemText primary={item.title} secondary={`${item.price}THB x ${item.quantity}`} />
                      </ListItem>
                    </Link>
                  )
                })
              }
            </List>
            {
              showBuy && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => setModal(true)}
                  disabled={cartAdded}
                >
                  {cartAdded ? 'อยู่ในตะกร้า' : 'ใส่ตะกร้า'}
                </Button>
              )
            }
          </Box>
        </Box>
      </Grow>
    </>
  )
}