import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { createPromotionByIdSelector } from '../../reducers/promotions';
import { renderChatDate } from '../../helpers/date';
import { displayCurrency } from '../../helpers/currency';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  dialogCustomizedWidth: {
    width: '75%',
    margin: '20px',
  },
  dialogContent: {
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'black',
    padding: '5px'
  },
}));

const PromotionDialog = ({ open, promotionId, closeDialog }) => {
  const classes = useStyles();
  const promotion = useSelector(state => createPromotionByIdSelector(state, promotionId));

  if (!promotion) return null;

  const { name, type, discount_price, discount_percent, minimum_price, first_time, quota, limit_per_customer, start_date, end_date, use_for } = promotion.promotion;

  let useFor = "";
  if (use_for.includes("appointment")) {
    useFor += "ปรึกษาแพทย์, "
  } if (use_for.includes("lab")) {
    useFor += "แล็ป, "
  } if (use_for.includes("shop")) {
    useFor += "ยา"
  }

  const text = <>
    <p>- ส่วนลด {type === 'price_discount' ? <>{displayCurrency(discount_price)}</> : <>{parseInt(discount_percent)}%</>}</p>
    {/* <p>- ใช้ได้กับ {useFor}</p> */}
    {useFor ? <p>- ใช้ได้กับ {useFor}</p> : null}
    {minimum_price !== '0.00' ? <p>- ขั้นต่ำ {minimum_price}</p> : null}
    {first_time === true ? <p>- สำหรับลูกค้าที่ใช้ส่วนลดครั้งแรกเท่านั้น</p> : null}
    {quota !== 0 ? <p>- โค้ดส่วนลดมีจำนวนจำกัด</p> : null}
    {limit_per_customer !== 0 ? <p>- จำกัดการใช้สิทธิ์ 1 คน/{limit_per_customer} ครั้ง</p> : null}
    <p>- โค้ดส่วนลดไม่สามารถแลกเปลี่ยนหรือทอนเป็นเงินสดได้</p>
    <p>- โค้ดส่วนลดไม่สามารถโอนสิทธิ์การใช้งานให้แก่ผู้อื่นได้</p>
    <p>- เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</p>
  </>

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='xl'
      onClose={closeDialog}
      classes={{
        paperFullWidth: classes.dialogCustomizedWidth,
      }}
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
        {/* {image !== null ?
          <img alt={name} src={image} width="100%" />
        : null} */}
        <List>
          <ListItem>
            <ListItemText
              primary={<strong>{name}</strong>}
              secondary={<span>ใช้ได้ตั้งแต่ {renderChatDate(start_date)} {end_date !== null ? <> - {renderChatDate(end_date)}</>: null}</span>} />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary={<strong>เงื่อนไขและข้อกำหนด</strong>} secondary={text} />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default PromotionDialog;
