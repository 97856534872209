import React from 'react';
import { checkConsentNews, postConsentNews } from '../../apis';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default ({}) => {
  const [ open, setOpen ] = React.useState();
  const [ news, setNews ] = React.useState();

  React.useEffect(() => {
    check();
  }, []);

  const check = async () => {
    try {
      const res = await checkConsentNews();
      console.log('response', res);
      if (res.is_first_time) {
        setOpen(!!res.is_first_time);
      }
    } catch (e) {}
  }

  const checkConsent = () => {
    setNews(!news);
  }

  const accept = async (value) => {
    let payload = {is_accept: value};
    await postConsentNews(payload);
    setOpen(null);
  }

  return (
    <Dialog
      open={!!open}
      onClose={accept}
      scroll='body'
    >
      <DialogTitle>กรุณายอมรับเงื่อนไขการให้บริการ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ฉันยินยอมให้ หมอ ออนไลน์ นำเสนอหรือส่งข่าวสาร ประชาสัมพันธ์ ส่งการแจ้งเตือน นำเสนอเกี่ยวกับสิทธิประโยชน์
          โปรโมชั่นสำหรับผลิตภัณฑ์และบริการ แคมเปญที่เป็นประโยชน์ หรือเชิญชวนเข้าร่วมกิจกรรมต่างๆ
          ผ่านช่องทางการติดต่อที่ท่านได้ให้ไว้กับ หมอ ออนไลน์
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => accept(false)} color='primary'>
          ยกเลิก
        </Button>
        <Button onClick={() => accept(true)} color='primary'>
          ยอมรับเงื่อนไข
        </Button>
      </DialogActions>
    </Dialog>
  )
}