import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Twilio from '../apis/twilio';
import * as Constant from '../actions/constants';

export default () => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);

  useEffect(() => {
    fn();
  }, []);

  const fn = async () => {
    const payload = await Twilio.init();
    dispatch({ type: Constant.BECOME_TWILIO, payload });
  }

  return session.twilio ? Twilio : null;
}
