import * as Constant from './constants'
import * as Twilio from '../apis/twilio'
import { createVideoRoom } from '../apis/dailyco';

export const getChannelMessages = (channel) => {
  return dispatch => {
    dispatch({ type: Constant.MESSAGES_GET_REQUEST });
    return Twilio.getChannelMessages(channel).then(payload => {
      dispatch({ type: Constant.MESSAGES_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.MESSAGES_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const createMessage = (channel, message) => {
  return dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    return Twilio.createMessage(channel, message).then(() => {
      return Twilio.getChannelMessages(channel);
    }).then(payload => {
      dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const createVideoMessage = (channel) => {
  return async dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    try {
      // const res = await createVideoRoom();
      // const url = `${res.url}?t=${res.token}`;
      const url = 'https://moronline.daily.co/BHVWZAzDPvVN9CMNCl1q';
      const msg = {
        type: 'videocall',
        value: { url }
      }
      await Twilio.createMessage(channel, JSON.stringify(msg));
      let payload = await Twilio.getChannelMessages(channel);
      dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
      return payload;
    } catch (message) {
      dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
      return message;
    }
  }
}
