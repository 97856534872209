import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch(action.type) {
    case Constants.CREDIT_CARD_PATCH_SUCCESS:
    case Constants.CREDIT_CARD_POST_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }
    case Constants.CREDIT_CARDS_GET_SUCCESS: {
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state);
    }
    case Constants.CREDIT_CARDS_REMOVE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.id];
      return {
        ...newState,
      }
    }
    default: return state;
  }
}

export default combineReducers({
  byId,
});

export const createAllCreditCardSelector = state => Object.keys(state.creditCards.byId).map(id => state.creditCards.byId[id]);
export const createCreditCardSelector = (state, id) => state.creditCards.byId[id];

