import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

function SimpleDialog({ onClose, open, onItemClick, title, items, disabledItems = [] }) {

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <List>
        {items.map((item, index) => (
          <ListItem button onClick={() => onItemClick(index)} key={item} disabled={disabledItems.includes(index)}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default SimpleDialog;
