import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import navigate from '../../helpers/crossPlatformLink';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';

export default () => {
  const location = useLocation();
  const uri = location.search.split("?uri=")[1];
  const { source } = useSelector(state => state.session);

  React.useEffect(() => {
    if (uri) {
      window.location.href = uri;
    }
  }, [uri])

  if (!uri) {
    return null;
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Box p={2}>
          <h3>ชำระเงินผ่านบัตรเครดิต</h3>
          <small>กรุณาปิดหน้านี้หากท่านชำระเงินเรียบร้อยแล้ว</small>
          <Box width="100%" textAlign="center" mt={2}>
            <PaymentOutlinedIcon style={{ color: green[500], fontSize: 50 }} />
          </Box>
          <Box mt={1}>
            <Button
              fullWidth
              variant='contained'
              onClick={() => navigate(source, 'me.moronline://exit')}
            >
              ปิดหน้านี้
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
