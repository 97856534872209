
export const debounce = (func, delay) => {
  let inDebounce
  return function() {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function() {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export function subtotal(items) {
  if (!Array.isArray(items)) return 0;
  return items.map(({ item_price, quantity }) => parseFloat(item_price) * quantity).reduce((sum, i) => sum + i, 0);
}

export function calculateByItem(item) {
  return (
    parseFloat(item.item_price) * item.quantity
  )
};
