import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflowX: 'auto',
  },
}));

export default memo(({ children, title, subtitle, align = 'center' }) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.root}>
      {
        title && (
          <Typography variant="h6" component="h6" align={align}>
            {title}{subtitle && <small> {subtitle}</small>} 
          </Typography>
        )
      }
      {children}
    </Paper>
  )
});
