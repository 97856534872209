import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createForm } from 'rc-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { postRequestOTP, postVerifyOTP } from '../../apis';
import { getUrlParams } from '../../helpers/location';
import navigate from '../../helpers/crossPlatformLink';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import OTPInput from '../../components/OTPInput';
import otpimg from '../../assets/images/otp_image.jpg'

const useStyles = makeStyles(theme => ({
  phoneField: {
    backgroundColor: 'white'
  },
  subtitle: {
    fontSize: '0.9em',
    textAlign: 'center'
  },
  otp: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px'
  },
  otpInput: {
    width: '40px !important',
    height: '60px  !important',
    fontSize: '2em',
  },
  red: {
    color: 'red'
  }
}));


const OtpPage = ({ form }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = getUrlParams(location.search);
  const { returnUri, noSkip } = query;
  const { source, user } = useSelector(state => state.session);
  const [otp, setOTP] = useState("");
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.is_verify) {
      passOtpAction();
    }
  }, [user]);

  const passOtpAction = () => {
    if (returnUri) {
      history.replace(returnUri);
    } else {
      navigate(source, returnUri || 'me.moronline://exit');
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        setPhone(values.phone);
        handleRequestOTP(values.phone);
      }
    })
  }

  const handleRequestOTP = async (phone) => {
    setLoading(true);
    try {
      const res = await postRequestOTP(phone);
      setToken(res.data.token);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const handleOTPChange = async (value) => {
    setError(false);
    setOTP(value)
    if (value.toString().length === 4) {
      try {
        await postVerifyOTP(value, phone, token);
        passOtpAction();
      } catch (e) {
          setLoading(false);
          setError(true);
      }
    }
  }

  const clickSkip = (e) => {
    e.preventDefault();
    passOtpAction();
  }

  if (user?.is_verify) {
    return null;
  }

  return (
    <Box textAlign="center" mt="-64px">
      {
        !token ? (
          <>
            <img src={otpimg} width="70%" />
            <h3 align="center">ยืนยันตัวตนด้วยเบอร์โทรศัพท์</h3>
            <p className={classes.subtitle}>ความเป็นส่วนตัวของคุณคือสิ่งสำคัญของเรา ยืนยันหมายเลขโทรศัพท์ให้เสร็จสมบูรณ์เพื่อปกป้องบัญชีของคุณ</p>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={6}>
                {form.getFieldDecorator('phone', {
                  rules: [{
                    required: true,
                    min: 10,
                  }],
                })(
                  <TextField
                    className={classes.phoneField}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+66</InputAdornment>,
                      style: { textAlign: 'center' }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Box my={2}>
              <Button
                disabled={loading || form.getFieldError('phone') || !form.getFieldValue('phone')}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                ดำเนินการต่อ
              </Button>
              {
                !noSkip && (
                  <Box mt={4}>
                    ยังไม่ต้องการใส่ข้อมูล?
                    <Link href="#" onClick={clickSkip} >
                      {' ข้ามขั้นตอนนี้'}
                    </Link>
                  </Box>
                )
              }
            </Box>
          </>
        ) : (
          <>
            <img src={otpimg} width="90%" />
            <h3 align="center">ยืนยันตัวตนด้วยเบอร์โทรศัพท์</h3>
            <p className={classes.subtitle}>กรุณากรอกรหัสที่ส่งไปยังมือถือของท่าน +66XXXXX{form.getFieldValue('phone')?.slice(6)}</p>
            <Box my={1}>
              <OTPInput
                OTPLength={4}
                otpType="number"
                className={classes.otp}
                inputClassName={classes.otpInput}
                onChange={handleOTPChange}
                value={otp}
                error={error}
              />
            </Box>
          </>
        )
      }
    </Box>
  )

}

export default createForm()(OtpPage);
