import moment from 'moment';

export const getDate = (date) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const deliveryTomorrow = () => {
  let daysToAdd = 0;
  if (new Date().getHours() >= 19) daysToAdd = 1;
  return moment().add(daysToAdd, 'days').set({ hour:10,minute:0,second:0,millisecond:0 }).toDate();
}

export const renderChatTime = (date) => {
  return moment(date).format('LT');
}

export const renderChatDate = (date) => {
  return moment(date).format("Do MMM");
}

export const isSameDate = (d1, d2) => {
  const date1 = d1.toLocaleString().replace(/ /g, "").split(",")[0];
  const date2 = d2.toLocaleString().replace(/ /g, "").split(",")[0];
  return date1.localeCompare(date2) === 0;
}