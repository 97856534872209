
export const LOCATION_CHANGE = 'location/change';

export const BECOME_APP_TOKEN = 'auth/appToken';
export const BECOME_LINE = 'auth/line';
export const BECOME_FACEBOOK = 'auth/facebook';
export const BECOME_TWILIO = 'auth/twilio';
export const BECOME_SOURCE = 'become/source';
export const BECOME_USER = 'auth/user';
export const NO_AUTHENTICATION = 'auth/anonymous';

export const UPDATE_ME_REQUEST = 'update/me/request';
export const UPDATE_ME_SUCCESS = 'update/me/success';
export const UPDATE_ME_FAILED = 'update/me/request';

export const OTP_VERIFY_POST_REQUEST = 'otp/verify/post/request';
export const OTP_VERIFY_POST_SUCCESS = 'otp/verify/post/success';
export const OTP_VERIFY_POST_FAILED = 'otp/verify/post/failed';

export const OTP_POST_REQUEST = 'otp/post/request';
export const OTP_POST_SUCCESS = 'otp/post/success';
export const OTP_POST_FAILED = 'otp/post/failed';

export const LOGIN_REQUEST = 'login/get/request';
export const LOGIN_SUCCESS = 'login/get/success';
export const LOGIN_FAILED = 'login/get/failed';

export const LOGIN_LINE_REQUEST = 'login/line/get/request';
export const LOGIN_LINE_SUCCESS = 'login/line/get/success';
export const LOGIN_LINE_FAILED = 'login/line/get/failed';

export const LOGOUT_REQUEST = 'logout/get/request';
export const LOGOUT_SUCCESS = 'logout/get/success';
export const LOGOUT_FAILED = 'logout/get/failed';

export const CHANNEL_POST_REQUEST = 'channel/post/request';
export const CHANNEL_POST_SUCCESS = 'channel/post/success';
export const CHANNEL_POST_FAILED = 'channel/post/failed';

export const CHANNEL_DELETE_REQUEST = 'channel/delete/request';
export const CHANNEL_DELETE_SUCCESS = 'channel/delete/success';
export const CHANNEL_DELETE_FAILED = 'channel/delete/failed';

export const CHANNEL_GET_REQUEST = 'channel/get/request';
export const CHANNEL_GET_SUCCESS = 'channel/get/success';
export const CHANNEL_GET_FAILED = 'channel/get/failed';

export const CHANNELS_GET_REQUEST = 'channels/get/request';
export const CHANNELS_GET_SUCCESS = 'channels/get/success';
export const CHANNELS_GET_FAILED = 'channels/get/failed';

export const CHANNELS_ALL_GET_REQUEST = 'channels/all/get/request';
export const CHANNELS_ALL_GET_SUCCESS = 'channels/all/get/success';
export const CHANNELS_ALL_GET_FAILED = 'channels/all/get/failed';

export const MESSAGES_GET_REQUEST = 'messages/get/request';
export const MESSAGES_GET_SUCCESS = 'messages/get/success';
export const MESSAGES_GET_FAILED = 'messages/get/failed';

export const MESSAGE_POST_REQUEST = 'messages/post/request';
export const MESSAGE_POST_SUCCESS = 'messages/post/success';
export const MESSAGE_POST_FAILED = 'messages/post/failed';

export const SYMPTOM_ASSIGN_POST_REQUEST = 'symptom/assign/post/request';
export const SYMPTOM_ASSIGN_POST_SUCCESS = 'symptom/assign/post/success';
export const SYMPTOM_ASSIGN_POST_FAILED = 'symptom/assign/post/failed';

export const SYMPTOM_CLOSE_POST_REQUEST = 'symptom/close/post/request';
export const SYMPTOM_CLOSE_POST_SUCCESS = 'symptom/close/post/success';
export const SYMPTOM_CLOSE_POST_FAILED = 'symptom/close/post/failed';

export const SYMPTOM_POST_REQUEST = 'symptom/post/request';
export const SYMPTOM_POST_SUCCESS = 'symptom/post/success';
export const SYMPTOM_POST_FAILED = 'symptom/post/failed';

export const SYMPTOMS_GET_REQUEST = 'symptoms/get/request';
export const SYMPTOMS_GET_SUCCESS = 'symptoms/get/success';
export const SYMPTOMS_GET_FAILED = 'symptoms/get/failed';

export const SYMPTOM_GET_REQUEST = 'symptom/get/request';
export const SYMPTOM_GET_SUCCESS = 'symptom/get/success';
export const SYMPTOM_GET_FAILED = 'symptom/get/failed';

export const MENU_GET_REQUEST = 'menu/get/request';
export const MENU_GET_SUCCESS = 'menu/get/success';
export const MENU_GET_FAILED = 'menu/get/failed';

export const MENUS_GET_REQUEST = 'menus/get/request';
export const MENUS_GET_SUCCESS = 'menus/get/success';
export const MENUS_GET_FAILED = 'menus/get/failed';

export const MENUS_SEARCH_REQUEST = 'menus/search/request';
export const MENUS_SEARCH_SUCCESS = 'menus/search/success';
export const MENUS_SEARCH_FAILED = 'menus/search/failed';

export const MENUS_BY_CATEGORY_REQUEST = 'menus/category/request';
export const MENUS_BY_CATEGORY_SUCCESS = 'menus/category/success';
export const MENUS_BY_CATEGORY_FAILED = 'menus/category/failed';

export const SET_PAYMENT_TYPE = 'set/payment/type';
export const SET_PROMPTPAY = 'set/promptpay';
export const SET_CASH = 'set/cash';
export const SET_SHIPPING_INFO = 'set/shipping/info';
export const SET_SHIPPING_PRICE = 'set/shipping/price';
export const SET_SHIPPING_PRICE_LIST = 'set/shipping/pricelist';
export const SET_SHIPPING_FIXED_PRICE_LIST = 'set/shipping/fixedpricelist';

export const MENU_CLEAR = 'menu/clear';
export const MENU_ADD = 'menu/add';
export const MENU_REMOVE = 'menu/remove';
export const MENU_SET_QUANTITY = 'menu/set/quantity';

export const ORDER_POST_REQUEST = 'order/post/request';
export const ORDER_POST_SUCCESS = 'order/post/success';
export const ORDER_POST_FAILED = 'order/post/failed';

export const ORDER_GET_REQUEST = 'order/get/request';
export const ORDER_GET_SUCCESS = 'order/get/success';
export const ORDER_GET_FAILED = 'order/get/failed';

export const ORDERS_GET_REQUEST = 'orders/get/request';
export const ORDERS_GET_SUCCESS = 'orders/get/success';
export const ORDERS_GET_FAILED = 'orders/get/failed';

export const ORDER_SUMMARY_POST_REQUEST = 'order/summary/post/request';
export const ORDER_SUMMARY_POST_SUCCESS = 'order/summary/post/success';
export const ORDER_SUMMARY_POST_FAILED = 'order/summary/post/failed';

export const CATEGORIES_GET_REQUEST = 'categories/get/request';
export const CATEGORIES_GET_SUCCESS = 'categories/get/success';
export const CATEGORIES_GET_FAILED = 'categories/get/failed';

export const PHARMACIST_GET_REQUEST = 'pharmacist/get/request';
export const PHARMACIST_GET_SUCCESS = 'pharmacist/get/success';
export const PHARMACIST_GET_FAILED = 'pharmacist/get/failed';

export const PHARMACISTS_GET_REQUEST = 'pharmacists/get/request';
export const PHARMACISTS_GET_SUCCESS = 'pharmacists/get/success';
export const PHARMACISTS_GET_FAILED = 'pharmacists/get/failed';

export const CREDIT_CARD_POST_REQUEST = 'credit/post/request';
export const CREDIT_CARD_POST_SUCCESS = 'credit/post/success';
export const CREDIT_CARD_POST_FAILED = 'credit/post/failed';

export const CREDIT_CARDS_GET_REQUEST = 'credits/get/request';
export const CREDIT_CARDS_GET_SUCCESS = 'credits/get/success';
export const CREDIT_CARDS_GET_FAILED = 'credits/get/failed';

export const CREDIT_CARDS_REMOVE_REQUEST = 'credits/remove/request';
export const CREDIT_CARDS_REMOVE_SUCCESS = 'credits/remove/success';
export const CREDIT_CARDS_REMOVE_FAILED = 'credits/remove/failed';

export const CREDIT_CARD_PATCH_REQUEST = 'credit/patch/request';
export const CREDIT_CARD_PATCH_SUCCESS = 'credit/patch/success';
export const CREDIT_CARD_PATCH_FAILED = 'credit/patch/failed';

export const ADDRESS_POST_REQUEST = 'address/post/request';
export const ADDRESS_POST_SUCCESS = 'address/post/success';
export const ADDRESS_POST_FAILED = 'address/post/failed';

export const ADDRESSES_GET_REQUEST = 'addresses/get/request';
export const ADDRESSES_GET_SUCCESS = 'addresses/get/success';
export const ADDRESSES_GET_FAILED = 'addresses/get/failed';

export const COLLABORATION_GET_REQUEST = 'collaboration/get/request';
export const COLLABORATION_GET_SUCCESS = 'collaboration/get/success';
export const COLLABORATION_GET_FAILED = 'collaboration/get/failed';

export const SET_TENANT   = 'set/tenant';

export const USERS_DESCRIPTOR_GET_REQUEST = 'users/descriptor/get/request';
export const USERS_DESCRIPTOR_GET_SUCCESS = 'users/descriptor/get/success';
export const USERS_DESCRIPTOR_GET_FAILED = 'users/descriptor/get/failed';

export const SET_PROMOTION   = 'set/promotion';
export const REMOVE_PROMOTION   = 'remove/promotion';
export const PROMOTION_GET_REQUEST = 'promotion/get/request';
export const PROMOTION_GET_SUCCESS = 'promotion/get/success';
export const PROMOTION_GET_FAILED = 'promotion/get/failed';
