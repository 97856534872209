import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addMenu, removeMenu } from '../../actions/menus';
import { displayCurrency } from '../../helpers/currency';
import { createSelectedMenusSelector } from '../../reducers/menus';
import { createLoadingSelector } from '../../reducers/api';
import { postOrder, postOrderSummary } from '../../actions/orders';
import { subtotal } from '../../helpers/utility';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import OrderSummary from '../../components/OrderSummary';
import WrapSection from '../../components/WrapSection';
import Payment from '../../components/Payment';
import ShippingInfo from '../../components/ShippingInfo';
import Button from '../../components/Button';
import Promotion from '../../components/Promotion';
import CheckoutSummary from '../../components/CheckoutSummary';
import PromotionAlertCheckout from '../../components/PromotionAlertCheckout';


const CheckoutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const order = useSelector(createSelectedMenusSelector);
  const isLoading = useSelector(createLoadingSelector(['order/summary/post', 'order/post']))
  const session = useSelector(state => state.session);
  const [summary, setSummary] = React.useState();
  const shippingInfo = useSelector(state => state.shippingInfo);

  const subTotalPrice = subtotal(order.items);
  const discountPrice = 0;//getDiscountPrice(selectedPromotion, subTotalPrice, shippingInfo.shippingCost);
  const deliveryPrice = shippingInfo.deliveryPrice;
  // const orderPrice = subTotalPrice - discountPrice;
  const grandTotalPrice = subTotalPrice + deliveryPrice - discountPrice;

  React.useEffect(() => {
    window.logEvent('begin_checkout', { currency: 'THB', value: grandTotalPrice });
  }, []);

  React.useEffect(() => {
    const { address } = shippingInfo;
    if (address) {
      // dispatch(getShippingPrice(address.latitude, address.longitude, subTotalPrice));
      fetchSummary();
    }
  }, [subTotalPrice, shippingInfo.address, shippingInfo.deliveryMethod]);

  React.useEffect(() => {
    fetchSummary();
  }, [shippingInfo.promotionId]);

  const fetchSummary = async () => {
    try {
      const res = await dispatch(postOrderSummary(session.user.is_member));
      setSummary(res);
    } catch (e) {
      setSummary();
      if (e.message) {
        if (e.message.detail && e.message.detail.includes('address')) {
          alert('คุณอยู่นอกพื้นที่ให้บริการ');
        }
      }
    }
  }

  const onSubmit = async () => {
    try {
      const res = await dispatch(postOrder(session.user.is_member));
      const url = res.payment_redirect_uri;
      if (url) {
        if (url.includes('/scanPromptpay?')) {
          history.push(`/scanPromptpay?${url.split('/scanPromptpay?')[1]}`);
        } else if (url.includes('/mbanking?')) {
          history.push(`/mbanking?${url.split('/mbanking?')[1]}`);
        } else {
          history.push(`/cardpayment?uri=${url}`);
        }
      } else {
        throw 'error';
      }
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  };

  const disableButton = isLoading || !summary || !shippingInfo.payment;

  return (
    <Grid container direction="column">
      <Grid item>
        <PromotionAlertCheckout />
        <WrapSection title="คำสั่งซื้อ">
          <OrderSummary
            order={order}
            onSelect={(id) => dispatch(addMenu(id))}
            onDeselect={(id) => dispatch(removeMenu(id))}
          />
        </WrapSection>
        <WrapSection title="รายละเอียด">
          <ShippingInfo />
        </WrapSection>
        <WrapSection>
          <Payment />
        </WrapSection>
        <WrapSection>
          <Promotion filter="shop" />
        </WrapSection>
        {
          summary && (
            <WrapSection>
              <CheckoutSummary deliveryTitle="ค่าส่ง" summary={summary} />
            </WrapSection>
          )
        }
        {
          !disableButton && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              ชำระเงิน {summary && displayCurrency(summary.grand_total_price < 0 ? 0 : summary.grand_total_price)}
            </Button>
          )
        }
        <Link to="/createChannel">
          <Button
            fullWidth
            variant="contained"
            style={{ margin: '8px 0' }}
          >
            ติดต่อเภสัช
          </Button>
        </Link>
      </Grid>
    </Grid>
  )
}

export default CheckoutPage;
