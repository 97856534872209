import {
  Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import history from './helpers/history';
import Box from '@material-ui/core/Box';
import AppBar from './components/AppBar';
import DetectLocationChange from './components/DetectLocationChange';
import GlobalLoader from './components/GlobalLoader';
import CreateChannelPage from './pages/CreateChannelPage';
import ListChannelPage from './pages/ListChannelPage';
import ChannelPage from './pages/ChannelPage';
import CheckoutPage from './pages/CheckoutPage';
import ProductPage from './pages/ProductPage';
import MenuPage from './pages/MenuPage';
import OtpPage from './pages/OtpPage';
import AddCardPage from './pages/AddCardPage';
import PickLocationPage from './pages/PickLocationPage';
import ScanPromptPay from './pages/ScanPromptPay';
import MBanking from './pages/MBanking';
import CardPayment from './pages/CardPayment';
import LoginPage from './pages/LoginPage';
import LineLoginCallback from './pages/lineLoginCallback';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    maxWidth: '640px',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    overflow: 'auto',
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  noPaddingTop: {
    paddingTop: theme.spacing(1),
  }
}));

function App() {
  const classes = useStyles();
  const session = useSelector(state => state.session);

  return (
    <Router history={history}>
      <DetectLocationChange />
      <AppBar />
      <GlobalLoader />
      {/* <Box className={`${classes.container} ${!!session.appToken && classes.noPaddingTop}`}> */}
      <Box className={classes.container}>
        <Switch>
          <Route path="/otp" exact>
            <OtpPage />
          </Route>
          {
            session.isReady && (
              <Switch>
                <Route path="/login" exact>
                  <LoginPage />
                </Route>
                <Route path="/lineLoginCallback" exact>
                  <LineLoginCallback />
                </Route>
                <Route path="/menu" exact>
                  <MenuPage />
                </Route>
                <Route path="/createChannel" exact>
                  <CreateChannelPage />
                </Route>
                <Route path="/listChannel" exact>
                  <ListChannelPage />
                </Route>
                <Route path="/channel/:channelId" exact>
                  <ChannelPage />
                </Route>
                <Route path="/product/:productId" exact>
                  <ProductPage />
                </Route>
                <Route path="/checkout" exact>
                  <CheckoutPage />
                </Route>
                <Route path="/pickLocation" exact>
                  <PickLocationPage />
                </Route>
                <Route path="/addCard" exact>
                  <AddCardPage />
                </Route>
                <Route path="/scanPromptPay" exact>
                  <ScanPromptPay />
                </Route>
                <Route path="/mbanking" exact>
                  <MBanking />
                </Route>
                <Route path="/cardpayment" exact>
                  <CardPayment />
                </Route>
              </Switch>
            )
          }
        </Switch>
      </Box>
    </Router>
  );
}

export default App;
