import * as Constant from './constants';
import * as Twilio from '../apis/twilio';

export const getChannelUsers = (channel) => {
  return dispatch => {
    dispatch({ type: Constant.USERS_DESCRIPTOR_GET_REQUEST });
    return Twilio.getChannelUsers(channel).then((payload) => {
      dispatch({ type: Constant.USERS_DESCRIPTOR_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.USERS_DESCRIPTOR_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}
