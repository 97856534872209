import * as Constant from './constants'
import * as Api from '../apis'

export const getCategories = () => {
  return dispatch => {
    dispatch({ type: Constant.CATEGORIES_GET_REQUEST });
    return Api.getCategories().then(payload => {
      dispatch({ type: Constant.CATEGORIES_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.CATEGORIES_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}
