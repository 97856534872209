import * as Constant from './constants';
import * as Api from '../apis';

export const getPharmacist = (id) => {
  return dispatch => {
    dispatch({ type: Constant.PHARMACIST_GET_REQUEST });
    return Api.getPharmacist(id).then((payload) => {
      dispatch({ type: Constant.PHARMACIST_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.PHARMACIST_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}

export const getPharmacists = () => {
  return dispatch => {
    dispatch({ type: Constant.PHARMACISTS_GET_REQUEST });
    return Api.getPharmacists().then((payload) => {
      dispatch({ type: Constant.PHARMACISTS_GET_SUCCESS, payload });
      return payload;
    }).catch(message => {
      dispatch({ type: Constant.PHARMACISTS_GET_FAILED, message });
      return Promise.reject(message);
    })
  }
}
