import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PAYMENTS } from '../../helpers/constants';
import navigate from '../../helpers/crossPlatformLink';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

export default () => {
  const location = useLocation();
  const { source } = useSelector(state => state.session);
  const uri = location.search.split("?uri=")[1];

  const onClick = () => {
    if (uri) {
      window.location.href = uri
    }
  }

  if (!uri) {
    return null;
  }

  const payment = PAYMENTS.find((val) => uri.includes(val.uriPattern));

  if (!payment) return null;

  return (

    <Grid container direction="column">
      <Grid item>
        <Box p={2}>
          <h3>กรุณาชำระเงินผ่าน Mobile App ของธนาคารของท่าน</h3>
          <small>เมื่อชำระเงินเรียบร้อยแล้วกรุณารอประมาณ 5-10 นาที</small>
          <Box width="100%" textAlign="center" mt={2}>
            <img src={require(`../../assets/creditcards/${payment.img}`).default} style={{ maxWidth: 120 }} />
          </Box>
          <Box mt={2}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              onClick={onClick}
            >
              ไปยัง {payment.title}
            </Button>
          </Box>
          <Box mt={1}>
            <Button
              fullWidth
              variant='contained'
              onClick={() => navigate(source, 'me.moronline://exit')}
            >
              ปิดหน้านี้
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
