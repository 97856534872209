import * as Constants from '../actions/constants'

const initialState = {
  appToken: null,
  line: null,
  user: null,
  facebook: null,
  twilio: null,
  isReady: false,
  source: 'browser', // browser, android, ios, messenger, liff
}

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case Constants.BECOME_SOURCE:
      return {
        ...state,
        source: action.payload,
      }
    case Constants.BECOME_APP_TOKEN:
      return {
        ...state,
        appToken: action.payload,
      };
    case Constants.BECOME_LINE:
      return {
        ...state,
        line: action.payload,
      };
    case Constants.BECOME_FACEBOOK:
      return {
        ...state,
        facebook: action.payload,
      };
    case Constants.BECOME_TWILIO:
      return {
        ...state,
        twilio: action.payload,
      };
    case Constants.LOGIN_LINE_SUCCESS:
    case Constants.BECOME_USER: {
      // action.payload.user_type = "pharmacist";
      return {
        ...state,
        user: action.payload,
        isReady: true,
      };
    }
    case Constants.NO_AUTHENTICATION:
      return {
        ...state,
        isReady: true,
      };
    default:
      return state;
  }
}
