import * as Constants from '../actions/constants';

const initialState = []

export default function Reducer(state = initialState, action) {
  switch(action.type) {
    case Constants.LOCATION_CHANGE: {
      return [];
    }
    case Constants.MESSAGE_POST_SUCCESS:
    case Constants.MESSAGES_GET_SUCCESS: {
      return action.payload.items;
    }
    default: return state;
  }
}
