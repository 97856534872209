import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}));

export default ({ user }) => {
  const classes = useStyles();
  if (!user) {
    return null;
  }
  const { attributes = {} } = user;
  const img = attributes.image || attributes.line_image;
  return <Avatar className={classes.small} src={img} >{user.friendlyName}</Avatar>
}