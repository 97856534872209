import * as Constant from '../../actions/constants'

export default () => next => action => {
  if (window.logEvent && action.type) {
    if (action.type === Constant.LOCATION_CHANGE) {
      window.logEvent('screen_view', {
        pathname: action.payload.pathname
      });
    } else {
      if (!action.type.match(/success|failed/)) {
        window.logEvent('web_action', { type: action.type });
      }
    }
  }
  return next(action);
}
