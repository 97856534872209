import { Box, Grow, Button } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';

export default ({
  message, date, self, author, onClick
}) => {

  if (message.type !== 'videocall') return null;
  
  const l = 0;
  const r = 0;
  const bgcolor = 'background.paper';
  const color = 'text.primary';
  const justifyContent = 'center';
  
  return (
    <Grow in>
      <Box
        flex="0 0 auto"
        my={1}
        pl={l}
        pr={r}
        display="flex"
        justifyContent={justifyContent}
      >
        <Box
          minWidth={0}
          py={1}
          px={2}
          bgcolor={bgcolor}
          color={color}
          borderRadius={16}
          boxShadow={2}
        >
          <div style={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
            <VideocamIcon />
          </div>
          <Button
            fullWidth
            size="medium"
            onClick={() => onClick(message)}
          >
            เข้าสนทนา
          </Button>
        </Box>
      </Box>
    </Grow>
  )
}