import React, { useEffect } from 'react';
import { getUrlParams } from '../helpers/location';
import { useDispatch } from 'react-redux';
import { becomeLineLogin } from '../actions/session';
import qs from 'qs';
import * as Api from '../apis';

const LineLoginCallback = ({ location }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getLineToken();
  }, []);

  function getLineToken() {
    const query = getUrlParams(window.location.search);
    const payload = {
      grant_type: 'authorization_code',
      code: query.code,
      redirect_uri: `${window.location.origin}/lineLoginCallback`,
      client_id: process.env.REACT_APP_LINE_CLIENT_ID,
      client_secret: process.env.REACT_APP_LINE_CLIENT_SECRET,
    };
    Api.getLineToken(qs.stringify(payload)).then(response => {
      dispatch(becomeLineLogin(response.data.access_token));
    });
  }

  return <></>;
};

export default LineLoginCallback;
