import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { renderChatTime } from '../../helpers/date';
import { pharmacistAssign } from '../../actions/symptoms';
import { getMessageBody } from '../../helpers/messages';
import { getUrlParams } from '../../helpers/location';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import notiAudio from '../../assets/mp3/swiftly-610.mp3';
import useTwilio from '../../hooks/useTwilio';
import SkeletonLoader from '../../components/SkeletonLoader';
import { createAllSymptomSelector } from '../../reducers/symptoms';

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer'
  },
  badge: {
    marginRight: 10
  },
  contactButton: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const getLastMessageDate = (ch) => {
  return (ch.attributes || {}).lastMessageDate;
}

const sortByDate = (a, b) => {
  const bDate = new Date(getLastMessageDate(b));
  const aDate = new Date(getLastMessageDate(a));
  return bDate - aDate;
}

const playSound = () => {
  var audio = new Audio(notiAudio);
  audio.loop = false;
  audio.play(); 
}

const ListChannelPage = () => {
  const [lastMessages, setLastMessages] = React.useState({});
  const [channels, setChannels] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Twilio = useTwilio();
  const location = useLocation();
  const params = getUrlParams(location.search);
  const { chatroomId } = params;
  const session = useSelector(state => state.session);

  const isPharmacist = session.user.user_type !== 'normal';

  React.useEffect(() => {
    if (Twilio) {
      fetchChannels();
    }
  }, [Twilio]);

  const fetchChannels = async () => {
    if (!Twilio) return;

    setLoading(true);

    let channelList = [];
    let channel = await Twilio.getChannels();

    while (channel.hasNextPage) {
      try {
        channelList = channelList.concat(channel.items);
        channel = await channel.nextPage()
      } catch (e) {}
    }

    if (!channel.hasNextPage) {
      channelList = channelList.concat(channel.items);
    }

    const chs = channelList.filter(item => item.attributes.room_type === 'pharmacist_room' && item.attributes.is_closed !== true)

    setLoading(false);
    setChannels(chs);
    fetchLastMessages(chs);

    if (isPharmacist && chatroomId != null) {
      try {
        await dispatch(pharmacistAssign(chatroomId));
        const conver = await Twilio.getChannelByUniqueName(chatroomId)
        Twilio.joinChannel(conver.sid).then(ch => {
          history.push(`/channel/${ch.sid}`);
        });
      } catch (e) {
        const cha = channelList.find(item => item.uniqueName === chatroomId)
        if (cha != null) {
          Twilio.joinChannel(cha.sid).then(ch => {
            history.push(`/channel/${ch.sid}`);
          });
        } else {
          alert('มีเภสัชกรท่านอื่นดูแลแล้ว ท่านไม่สามารถเข้าห้องแชทได้')
        }
      }
    }
  }

  // const fetchChannels = async (tab) => {
  //   if (!Twilio) return;

  //   setLoading(true);
  //   const res = await Twilio.getPublicChannelDescriptors();
  //   const sortedItems = res.items.sort(sortByDate);
  //   if (channels.length) { // Check for new message to play sound.
  //     if (getLastMessageDate(channels[0]) !== getLastMessageDate(sortedItems[0])) {
  //       playSound();
  //     }
  //   }

  //   setLoading(false);
  //   setChannels(sortedItems);
  //   fetchLastMessages(sortedItems);
  // }

  const fetchLastMessages = async (channels) => {
    if (!Twilio) return;

    const result = {...lastMessages};
    for (var i = 0; i < channels.length; i++) {
      const {sid} = channels[i];
      result[sid] = await Twilio.getLastMessageByChannelSid(sid);
    }
    setLastMessages(result);
  }

  const onClick = (ch) => {
    history.push(`/channel/${ch.sid}`);
  }

  if (loading && channels.length === 0) {
    return (
      <SkeletonLoader />
    );
  }

  return (
    <>
      <List>
        {
          channels.length === 0 && (
            <ListItem>
              <ListItemText primary="ไม่พบรายการ" />
            </ListItem>
          )
        }
        {
          channels.map(ch => {
            const msg = lastMessages[ch.sid];
            const isWaiting = ch.membersCount < 2;
            const primary = ch.friendlyName;
            // const hasNewMassage = !isWaiting && ch.messagesCount && (ch.lastConsumedMessageIndex !== ch.messagesCount - 1);
            let secondary, badge = null;

            if (msg) {
              secondary = getMessageBody(msg);
              if (secondary.type === 'text') {
                secondary = `${renderChatTime(msg.dateCreated)} - ${secondary.value}`;
              } else if (secondary.type === 'catalog') {
                secondary = `${renderChatTime(msg.dateCreated)} - Sent a prescription.`;
              } else if (secondary.type === 'videocall') {
                secondary = `${renderChatTime(msg.dateCreated)} - Video call.`;
              }
            }
            // if (hasNewMassage) {
            //   badge = <Badge color="primary" variant="dot" className={classes.badge}/>
            // };
            if (isWaiting) {
              secondary = 'Waiting...';
              badge = <Badge color="error" variant="dot" className={classes.badge}/>
            };
            return (
              <ListItem key={ch.sid} onClick={() => onClick(ch)} className={classes.item}>
                {badge}
                <ListItemText primary={primary} secondary={secondary} />
              </ListItem>
            )
          })
        }
      </List>
      {
        !isPharmacist && (
          <Link
            className={classes.contactButton}
            to="/createChannel?skip=1"
          >

            <Fab
              size="large"
              color="primary"
              variant="extended"
            >
                <AddIcon />
                <span>ห้องแชทใหม่</span>
            </Fab>
          </Link>
        )
      }
    </>
  );
}

export default ListChannelPage;
