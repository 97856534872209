import * as Constant from './constants'
import * as liff from '../apis/liff';
// import * as Twilio from '../apis/twilio';
import * as Api from '../apis';
// import history from '../helpers/history';
import navigate from '../helpers/crossPlatformLink';

export const mockPharmacist = (number) => {
  return dispatch => {
    return Api.mockPharmacist()
      .then(payload => {
        dispatch({ type: Constant.BECOME_USER, payload });
        return payload;
      }).catch(message => {
        return Promise.reject(message);
      });
  };
}

export const become = () => {
  return (dispatch, getState) => {
    return Api.become()
      .then(payload => {
        dispatch({ type: Constant.BECOME_USER, payload });
        return payload;
      }).catch(message => {
        // return Promise.reject(message);
        const { session } = getState();
        let source = session.source;
        const payload = window.location.pathname;
        if ((payload !== '/login') && (payload !== '/lineLoginCallback')) {
          localStorage.setItem('returnUri', `${payload}`);
          navigate(source, '/login');
        }
        dispatch({ type: Constant.NO_AUTHENTICATION });
      });
  };
}

/**
 * =============
 * TODO: Login with FB Messenger extension token
 * =============
 */
export const becomeFb = () => {
  // return dispatch => {
  //   return liff.initLiff()
  //     .then(payload => {
  //       dispatch({ type: Constant.BECOME_LINE, payload });
  //       return Api.logInLine(payload.accessToken);
  //     }).then(payload => {
  //       dispatch(getAddresses());
  //       dispatch(getCreditCards());
  //       dispatch({ type: Constant.BECOME_USER, payload });
  //       return payload;
  //     }).catch(message => {
  //       navigate('/otp?returnUri=' + window.location.pathname, {
  //         replace: true,
  //       })
  //       return Promise.reject(message);
  //     });
  // };
}

export const becomeAppToken = (token) => {
  return (dispatch) => {
    dispatch({ type: Constant.BECOME_APP_TOKEN, payload: token });
    return Api.logInToken(token).then(payload => {
        dispatch({ type: Constant.BECOME_USER, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.NO_AUTHENTICATION });
        return Promise.reject(message);
      });
  };
}

export const becomeLiff = (liffId) => {
  return (dispatch) => {
    return liff.initLiff(liffId)
      .then(payload => {
        dispatch({ type: Constant.BECOME_LINE, payload });
        return Api.logInLine(payload.accessToken);
      }).then(payload => {
        dispatch({ type: Constant.BECOME_USER, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.NO_AUTHENTICATION });
        return Promise.reject(message);
      });
  };
}

export const becomeLineLogin = accessToken => {
  return async (dispatch, getState) => {
    try {
      const { session } = getState();
      let source = session.source;
      let payload = await Api.logInLine(accessToken, {});
      dispatch({ type: Constant.BECOME_USER, payload });
      const returnUri = localStorage.getItem('returnUri');
      navigate(source, returnUri);
    } catch (message) {
      dispatch({ type: Constant.NO_AUTHENTICATION });
    }
  };
};

export const loginLine = (accessToken, phone, otpToken, pin) => {
  return (dispatch) => {
    dispatch({ type: Constant.LOGIN_LINE_REQUEST });
    return Api.logInLine(accessToken, phone, otpToken, pin)
      .then(payload => {
        dispatch({ type: Constant.LOGIN_LINE_SUCCESS, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.LOGIN_LINE_FAILED, message });
        return Promise.reject(message)
      })
  }
}

export const updateMe = (firstName, lastName) => {
  return dispatch => {
    dispatch({ type: Constant.UPDATE_ME_REQUEST });
    return Api.updateMe(firstName, lastName)
      .then(payload => {
        dispatch({ type: Constant.UPDATE_ME_SUCCESS, payload });
        return payload
      }).catch(message => {
        dispatch({ type: Constant.UPDATE_ME_FAILED, message });
        return Promise.reject(message)
      })
  }
}

export const postVerifyOTP = (pin, phone, token) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.OTP_VERIFY_POST_REQUEST });

    return Promise.resolve().then(() => {
      const { session } = getState();

      if (!session.user && !session.line && !session.facebook) {
        return Promise.reject('Session is required');
      }

      if (session.line) {
        return dispatch(loginLine(session.line.accessToken, phone, token, pin));
      } else if (session.facebook) {
        // TODO: Create account with facebook
      }

      return Promise.resolve();
    }).then(payload => {
      dispatch({ type: Constant.OTP_VERIFY_POST_SUCCESS, payload });
    }).catch(message => {
      dispatch({ type: Constant.OTP_VERIFY_POST_FAILED, message });
      return Promise.reject(message);
    });
  }
}

export const postRequestOTP = (phone) => {
  return (dispatch) => {
    dispatch({ type: Constant.OTP_POST_REQUEST });
    return Api.postRequestOTP(phone)
      .then(payload => {
        dispatch({ type: Constant.OTP_POST_SUCCESS, payload });
        return payload;
      }).catch(message => {
        dispatch({ type: Constant.OTP_POST_FAILED, message, alert: true });
        return Promise.reject(message);
      });
  }
}

export const becomeSource = (payload) => ({
  type: Constant.BECOME_SOURCE,
  payload
})
