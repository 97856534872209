import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuByIdSelector } from '../../reducers/menus';
import { getMenu } from '../../actions/menus';
import { displayCurrency } from '../../helpers/currency';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const ProductPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const product = useSelector(state => createMenuByIdSelector(state, params.productId));
  const { productId } = params;

  React.useEffect(() => {
    dispatch(getMenu(productId));
  }, [params]);

  if (!product) return null;

  const { name, price, image = {}, properties, indications, instruction, categories, is_out_of_stock } = product;
  
  return (
    <>
      <img alt={name} src={image.large}/>
      <List>
        <ListItem>
          <ListItemText
            primary={name}
            secondary={categories[0].name}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={<>{displayCurrency(price)} <small>{is_out_of_stock ? 'Out of stock' : 'Available'}</small></>}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Detail"
            secondary={properties}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Indication"
            secondary={indications}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Dosage"
            secondary={instruction}
          />
        </ListItem>
      </List>
    </>
  )
}

export default ProductPage;
